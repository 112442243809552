import axios from "axios";
import qs from "qs";
import store from '@/store/index';
import {jsonHeader} from "@/helpers/axiosHeaders";

export default {
    axiosConfig(params) {
        return {
            withCredentials: true,
            params: params ? params : {},
            paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            },
            headers: {'Content-Type': 'application/json;charset=utf-8','Authorization': store.state.auth.localStorage.token},
        }
    },
    axiosConfigNoAuth(params) {
        return {
            params: params ? params : {},
            paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            },
            headers: jsonHeader,
        }
    },
    errorHandler(error){
        return new Promise((resolve, reject) => {
            switch (+error) {
                case 400:// Пользователь не существует
                    store.commit('auth/setFormAuthError', {
                        name: store.state.auth.forms.auth.fields.login.name,
                        value: true
                    }, {root: true});
                    resolve();
                    break;
                case 401:// Не верный токен
                    store.dispatch('auth/logout', {}, {root: true});
                    resolve();
                    break;
                case 402:// Пользователь заблокирован навсегда
                    store.dispatch('auth/logout', {}, {root: true});
                    store.commit('auth/setBanPermanent', true, {root: true});
                    store.commit('auth/setLoaded', true, {root: true});
                    resolve();
                    break;
                case 403:// Пользователь заблокирован за превышение попыток авторизации
                    store.dispatch('auth/clearUserAuthInfo', {}, {root: true});
                    store.commit('auth/setBan', true, {root: true});
                    store.commit('auth/setLoaded', true, {root: true});
                    resolve();
                    break;
                case 404:// Ресурс не найден
                    resolve();
                    break;
                case 405: // Токен истек
                    store.dispatch('auth/checkRefreshToken', {}, {root: true}).then(()=>{
                        setTimeout(()=>{
                            store.commit('auth/setLoaded', true, {root: true});
                        },500)
                        resolve();
                    });
                    break;
                case 406:// Refresh Токен истек
                    store.dispatch('auth/clearUserAuthInfo', {}, {root: true});
                    store.commit('auth/setLoaded', true, {root: true});
                    resolve();
                    break;
                case 407:// Пользователю недосупен раздел
                    //store.dispatch('auth/clearUserAuthInfo', {}, {root: true});
                    store.commit('auth/setIsNoSuccessSection', true, {root: true});
                    store.commit('auth/setLoaded', true, {root: true});
                    resolve();
                    break;
                case 408:// У пользователя нет доступа
                    store.commit('auth/setIsNoSuccess', true, {root: true});
                    store.commit('auth/setLoaded', true, {root: true});
                    resolve();
                    break;
                case 409:// Не верный пароль
                    store.commit('auth/setFormAuthError', {
                        name: store.state.auth.forms.auth.fields.password.name,
                        value: true
                    }, {root: true});
                    resolve();
                    break;
            }
        });
    }
};