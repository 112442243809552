import axios from 'axios'
import api from "@/helpers/api";
import router from "@/router";
import store from "@/store";
import App from "@/App";
const state = {
    subMenuID: [],
    itemsTop: [],
    itemsSecond: [],
    itemsSecondBuh: [],
    itemsSecondNps: [
        {
            "name": "Новые",
            "id": "nps-collect-new",
            "code": "nps-collect-new",
            "link": "/nps-collect/nps-collect-new/",
            "title": "Сбор НПС",
            "description": "Заказчики, которых нужно обзвонить и узнать, насколько они готовы нас рекомендовать",
            "label": 0
        },
        {
            "name": "недозвон",
            "id": "nps-collect-not-call",
            "code": "nps-collect-not-call",
            "link": "/nps-collect/nps-collect-not-call/",
            "title": "Сбор НПС",
            "description": "Заказчики, до которых мы не дозвонились, а надо бы...",
            "label": 0
        },
        {
            "name": "получили земсдизайн",
            "id": "nps-collect-give-design",
            "code": "nps-collect-give-design",
            "link": "/nps-collect/nps-collect-give-design/",
            "title": "Получили ЗемсДизайн",
            "description": "Заказчики, которые получили ЗемсДизайн",
            "label": 0
        }
    ],
    itemTopActive: '',
    itemSecondActive: '',
}

// getters
const getters = {
}

const actions = {
    getMenu(context, data) {
        return new Promise((resolve, reject) => {
            //@todo быстрая смена вкладок, приводит к подгрузке списка не из этой вкладки
            let param = {type: 'top'};
            if(data && data.type){
                param.type = data.type;   
                 
            }
            if(data && data.isBuh && data.isSub){
                param.isSubBuh = true;
            }
            axios.get(process.env.VUE_APP_API_PATH+'/menus/',api.axiosConfig(param)).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }

                if(data && (data.isBuh || data.type=="devPeriod")){
                    if(data.isSub){
                        context.commit('setSubMenuBuh', response.data.result);
                    } else {
                        context.commit('setMenuBuh', response.data.result);
                    }
                } else {
                    context.commit('setMenu', response.data.result);
                }

                context.commit('setActivePath', {route: window.app.$router.currentRoute.value});
                resolve(response.data.result);
            }).catch(function (error) {
                resolve();
            });
        });
    },
    getLabels(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_PATH+'/menus/',api.axiosConfig({type: 'labels', statuses: state.subMenuID.join(',')})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                context.commit('setLabels', response.data.result);
            }).catch(function (error) {
                resolve();
            });
        });
    },
    getLabelsBuh(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_PATH+'/menus/',api.axiosConfig({type: 'labelsBuh'})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                if(data.isSub){
                    context.commit('setLabelsBuhSub', response.data.result);
                } else {
                    context.commit('setLabelsBuh', response.data.result);
                }
            }).catch(function (error) {
                resolve();
            });
        });
    },
}

const mutations = {
    setMenu(state, data) {
        if(typeof data === 'object' && data.items !== undefined) {
            state.itemsTop = [];
            state.itemsSecond = [];
            for(let ind in data.items) {
                state.itemsTop.push({
                    'id': data.items[ind].id,
                    'name': data.items[ind].name,
                    'code': data.items[ind].code,
                    'link': data.items[ind].link,
                    'label': data.items[ind].label,
                });


                //let arSub = '';
                state.itemsSecond[data.items[ind].code] = [];
                for(let ind2 in data.items[ind].items) {
                    state.itemsSecond[data.items[ind].code].push({
                        'id': data.items[ind].items[ind2].id,
                        'name': data.items[ind].items[ind2].name,
                        'code': data.items[ind].items[ind2].code,
                        'link': data.items[ind].items[ind2].link,
                        'title': data.items[ind].items[ind2].title,
                        'description': data.items[ind].items[ind2].description,
                        'label': data.items[ind].items[ind2].label,
                    });

                    state.subMenuID.push(data.items[ind].items[ind2].id);
                }
            }
        }
    },
    setMenuBuh(state, data) {
        if(typeof data === 'object' && data.items !== undefined) {
            state.itemsTop = [];
            for(let ind in data.items) {
                state.itemsTop.push({
                    'id': data.items[ind].id,
                    'name': data.items[ind].name,
                    'code': data.items[ind].user,
                    'label': data.items[ind].label,
                    'link': 'master'+data.items[ind].id,
                });
            }
        }
    },
    setSubMenuBuh(state, data) {
        if(typeof data === 'object' && data.items !== undefined) {
            state.itemsSecondBuh = [];
            for(let ind in data.items) {
                state.itemsSecondBuh.push({
                    'id': data.items[ind].id,
                    'name': data.items[ind].name,
                    'code': data.items[ind].user,
                    'label': data.items[ind].label,
                    'link': data.items[ind].id,
                });
            }
        }
    },
    setActionMenu(state, data){
        if(typeof data === 'object' && data.items !== undefined) {
            state.itemsAction = data.items;
        }
    },
    setActivePath(state, data) {
        let pageTopCode = '',
            pageSecondCode = '';

        if(typeof data === 'object' && data.route !== undefined) {
            let itemTopActive = {},
                itemSecondActive = {},
                routePath = data.route.path.split('/');

            if (routePath[1]) {
                pageTopCode = routePath[1];
            }

            if (routePath[2]) {
                pageSecondCode = routePath[2];
            }

            for(let i in state.itemsTop) {
                if (pageTopCode) {
                    if (state.itemsTop[i].code === pageTopCode) {
                        itemTopActive = state.itemsTop[i];
                        break;
                    }
                } else {
                    itemTopActive = state.itemsTop[i];
                    break;
                }
            }

            if (state.itemsSecond[itemTopActive.code] && state.itemsSecond[itemTopActive.code].length) {
                for(let i in state.itemsSecond[itemTopActive.code]) {
                    if (pageSecondCode) {
                        if (state.itemsSecond[itemTopActive.code][i].code === pageSecondCode) {
                            itemSecondActive = state.itemsSecond[itemTopActive.code][i];
                            break;
                        }
                    } else {
                        //если во вкладке payout есть что показать, тогда она должна открываться по умолчанию
                        if (itemTopActive.code === 'salaries' && state.itemsSecond[itemTopActive.code][1].label > 0) {
                            itemSecondActive = state.itemsSecond[itemTopActive.code][1];
                        } else {
                            itemSecondActive = state.itemsSecond[itemTopActive.code][i];
                        }

                        break;
                    }
                }
            }
            state.itemTopActive = itemTopActive;
            state.itemSecondActive = itemSecondActive;
        }
    },
    clearActivePath(state, data) {
        state.itemsTop = [];
        state.itemsSecond = [];
        state.itemTopActive = '';
        state.itemSecondActive = '';
    },
    setLabelsBuh(state, data) {
        for(let iTop in state.itemsTop) {
            state.itemsTop[iTop].label = data.items[state.itemsTop[iTop].id] ? data.items[state.itemsTop[iTop].id].count : 0;
        }
    },
    setLabelsBuhSub(state, data) {
        for(let iTop in state.itemsSecondBuh) {
            state.itemsSecondBuh[iTop].label = data.items[state.itemsSecondBuh[iTop].id] ? data.items[state.itemsSecondBuh[iTop].id].count : 0;
        }
    },
    setLabels(state, data) {
        if(data.salary){
            store.state.auth.user.salary = data.salary;   
        }
        for(let iTop in state.itemsTop) {
            let code = state.itemsTop[iTop].code;
            let labelTop = 0;

            for(let iSub in state.itemsSecond) {
                if (iSub === code) {
                    for (let iSubItem in state.itemsSecond[iSub]) {

                        let id = parseInt(state.itemsSecond[iSub][iSubItem].id);

                        if (typeof (data.items[id]) !== 'undefined') {
                            state.itemsSecond[iSub][iSubItem].label = data.items[id];
                            labelTop += parseInt(data.items[id]);
                        }
                        else{
                            state.itemsSecond[iSub][iSubItem].label = 0;
                        }
                    }
                }
            }
            state.itemsTop[iTop].label = labelTop;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
