<template>
    <div>
        <div class="popup__header --error">
            <div class="title">Ошибка!</div>
            <div class="text-color-white-trans text-bold mt4">Невозможно совершить действие</div>
        </div>


        <div class="popup__content">
            <div class="mt8" v-html="errorCustomText"></div>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex';

    export default {
        name: "ErrorCustomTextModal",
        methods: {
        },
        data: function () {
            return {

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                errorCustomText: state => state.popups.errorCustomText,
            }),
        },
    }
</script>

<style scoped>

</style>
