<template>
	<div class="" :class="{'tooltip-overlay': mobileVer && show}">
		<div class="w-tooltip"
	         :style="{display: inline ? 'inline-block' : 'block'}"
	        :class="[{'opened': show}, addClassWrap]"
	         ref="tooltipWrap"
	         v-clickoutside="tooltipClose">
	        <span ref="tooltipBtn" class="tooltip__btn" @click.prevent="btnClick" :class="addClassBtn" @mouseover="mouseOver" @mouseleave="mouseLeave">
	            <slot name="tooltipBtn"></slot>
	        </span>

	        <transition name="fade-popup">
	            <div class="tooltip"
	                 ref="tooltip"
	                 v-if="show && hasSlotContent($slots.tooltipContent) "
	                 :class="[addClass, tooltipDirection, {'--close-btn-big': btnBig}]"
	                 :style="addStyle">
	                <div v-if="isBtnClose" class="tooltip__close" @click.prevent="btnClick"></div>
	                <div class="tooltip__content" :style="{'padding':!isPadding ? '0': ''}">
	                    <slot name="tooltipContent"></slot>
	                </div>
	            </div>
	        </transition>
	    </div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import { isMobile } from 'mobile-device-detect';
    export default {
        name: "Tooltip",
        data: function() {
            return {
                show: false,
                tooltipDirection: this.direction,
				mobileVer: false,
            }
        },
        props: {
            inline: {default: false},
            direction: {default: 'top-center-direction'},
            addStyle: {default: ''},
            addClass: {default: ''},
            addClassWrap: {default: ''},
            addClassBtn: {default: ''},
            tempID: {default: ''},
            isChangeablePos: {
                default: function() {
                    return false;
                }
            },
            isBtnClose: {
                default: function () {
                    return true
                }
            },
            btnBig: {
                default: function () {
                    return false
                }
            },
            isPadding: {
                default: function () {
                    return true
                }
            },
            showOnHover:{
              default: function(){
                return false;
              }
            }
        },
		mounted(){
			
		},
		computed: {
            ...mapState({
                user: state => state.auth.user,
            }),
		},
        methods: {
			closeMobileTooltip(){
				if(document.querySelector('body').classList.contains('mobile-version') && this.show){
					this.tooltipClose();
				}
			},
            mouseLeave: function(){
              if(this.showOnHover){
                this.tooltipClose();
              }
            },
            mouseOver: function(){
              if(this.showOnHover){
                this.tooltipShow();
              }
            },
            btnClick: function() {
                if(this.show) {
                    this.tooltipClose();

                } else if(this.hasSlotContent(this.$slots.tooltipContent) ){
                    this.tooltipShow();
                }
            },
            tooltipClose: function() {
                let elem = this;
                this.show = false;

                setTimeout(() => {
                    this.$emit('tooltipclose', this.tempID);
                }, 100);
				if(this.mobileVer){
					document.querySelector('body').classList.remove('noscroll');
				 }
            },
            tooltipShow: function() {
                this.show = true;
                this.$emit('tooltipshow', this.tempID);
                this.tooltipDirection = this.direction;
                let self = this;
                setTimeout(function(){
                  let element = document.querySelector('.w-tooltip.opened .tooltip');
                  self.tooltipVisible(element);
				  if(self.mobileVer){
					  document.querySelector('body').classList.add('noscroll');
				   }
                },10);
            },

            tooltipVisible: function (target) {
                // Все позиции элемента
                var targetPosition = {
                    top: window.pageYOffset + target.getBoundingClientRect().top,
                    left: window.pageXOffset + target.getBoundingClientRect().left,
                    right: window.pageXOffset + target.getBoundingClientRect().right,
                    bottom: window.pageYOffset + target.getBoundingClientRect().bottom
                },
                // Получаем позиции окна
                windowPosition = {
                    top: window.pageYOffset,
                    left: window.pageXOffset,
                    right: window.pageXOffset + document.documentElement.clientWidth,
                    bottom: window.pageYOffset + document.documentElement.clientHeight
                };
                /* if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
                targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
                targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
                targetPosition.left < windowPosition.right) {
                */
                if (windowPosition.bottom - targetPosition.top < target.offsetHeight && this.isChangeablePos) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
                    this.tooltipDirection = this.direction.replace('bottom', 'top');//'top-center-direction';
                }
            },
            hasSlotContent(slot, props = {}) {
                return !this.isSlotEmpty(slot, props)
            },
            isSlotEmpty(slot, props = {}) {
                return this.isVNodeEmpty(slot?.(props));
            },
            isVNodeEmpty(vnode) {
                return !vnode || this.asArray(vnode).every(vnode => vnode.type === Comment);
            },
            asArray(arg) {
                return Array.isArray(arg) ? arg : arg != null ? [arg] : [];
            },

        },
        emits: ['tooltipshow', 'tooltipclose'],
        directives: {
            'clickoutside': {
                mounted: function(el, binding, vNode) {
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
                        //console.warn(warn);
                    }
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler;
                    document.addEventListener('click', handler);
                },

                unmounted: function(el, binding) {
                    document.removeEventListener('click', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null;
                }
            }
        }
    }


</script>

<style scoped>
    .fade-popup-enter-active, .fade-popup-leave-active {
        transition: opacity .15s;
    }
    .fade-popup-enter, .fade-popup-leave-to {
        opacity: 0;
    }
</style>
