<template>
    <div class="inp-s-wrap full" ref="inpWrap" :class="{ '--icon': isEdit || isValidate || isClearBtn, active: showLabel, fill: filled, 'inp-disabled': disabled, '--error': isError, 'repeat-error':  repeatError}">
        <span class="inp-s-label" :class="{ error: isError, 'repeat-error': repeatError }">{{ placeholder }}</span>

        <input :type="type"
            ref="field"
            :id="inputIdComputed"
            @keyup.enter="enter()"
            @focus="setFocus(true)"
            @blur="setFocus(false)"
            @keyup.ctrl.90="clearName"
            class="inp-s"
            :placeholder="showPlaceholder ? placeholderInput : ''"
            :class="[{ error: isError, disabled:disabled, 'repeat-error': repeatError }, classBtn]"
            :disabled="disabled"
            :value="modelValue"
            v-mask="mask"
            :name="nameInput"
            @paste="paste($event)"
            @input="updateValue($event.target.value)"
            :maxlength="maxLengthNum"
            :autocomplete="fieldAutocomplete"
			:inputmode="inputmode"
        >

        <span class="field-clear__btn" v-if="isClearBtn" @click="clear">
            <span class="i i-close2"></span>
        </span>

        <span class="inp-s-error" v-if="isError && !(errorText === 'Обязательное поле' && modelValue.toString().length > 0)">{{ errorText }}</span>

        <span class="inp-s-icon">
            <span class="i i-pencil2 --edit" v-if="isEdit" @click="edit"></span>
            <span class="i i-ok3" v-if="isValidate" @click="check"></span>
        </span>

        <span class="inp-s-errorIcon" v-if="isError">
            <span class="i i-info3"></span>
        </span>
        <span class="inp-s-counter" v-if="maxLengthNum">{{ maxLengthNum - modelValue.length }}</span>
    </div>
</template>

<script>
    import {number_format} from '@/helpers/formatted.js';

    export default {
        name: "InputStyled",
        props: {
            modelValue: {default: ''},
            placeholder: {default: ''},
            placeholderInput: {default: ''},
            inputId: {default: ''},
            disabled: {default: false},
            classBtn: {default: ''},
            isError: {default: false},
            isEdit: {default: false},
            isBlockArrowPhone: {default: false},
            isMoney: {default: false},
            maxMoney: {default: 9999999},
            isValidate: {default: false},
            errorText: {default: ''},
            mask: {default: ''},
            name: {default: 'name'},
            isClearBtn: {default: false},
            maxLength: {default: false},
            maxLengthNum: {default: ''},
            fieldAutocomplete: {default: 'off'},
            type: {default:'text'},
            autoFocus: {default: false},
			inputmode: {default: ''}
        },
        data: function () {
            return {
                focus: false,
                showPlaceholder: false,
                nameInput: '',
                repeatError: false,
                inputValue: '',
            }
        },
        mounted(){
            if(this.name !== 'name'){
                this.nameInput = this.name;
            } else {
                this.nameInput = Math.random().toString(36).substr(2, 5);
            }
            if(this.isBlockArrowPhone) {
                this.$refs.field.addEventListener("keydown", this.blockArrow, false);
            }
            if(this.autoFocus === true) {
                this.inputFocus('field');
            }
        },
        beforeUnmount() {
            this.$refs.field.removeEventListener('keydown', this.blockArrow);
        },
        emits: ['input', 'enter', 'check', 'focus', 'blur', 'edit', 'update:modelValue','paste'],
        computed: {
            showLabel() {
                return this.focus;
            },
            filled(){
                return this.modelValue.toString().length > 0;
            },
            inputIdComputed(){
                return this.inputId ? this.inputId : Math.random().toString(36).substr(2, 5);
            }
        },
        watch:{
            isError(val,oldVal){
                if(oldVal){
                    this.repeatError = true;
                }
                if(this.isError){
					if(this.$el.closest('.popup-wrapper')){
                        let $top = 0;
                        let pr = this.$el.closest('.popup-wrapper');
                        $top += parseFloat(this.$refs.inpWrap.getBoundingClientRect().top + this.$el.closest('.popup-wrapper').scrollTop) - 10;
                        pr.scrollTo({top: $top, left: 0, behavior: 'smooth'});
                    }
                }
            },
        },
        methods: {
            clearName(){
                if(this.name === 'nameCompany'){
                    let val = this.$refs.field.value.replace(/«/g,'').replace(/»/g,'');
                    this.$refs.field.value = val
                    this.$emit('update:modelValue', val);
                }
            },
            blockArrow(e){
                if ((e.keyCode >= 37 && e.keyCode <= 40)) {
                    e.preventDefault();
                    return false;
                }
            },
            updateValue: function (value, ispaste = false) {
                let v = value;
                if(this.isMoney) {
                    v = v.replace(/[-;":'a-zA-Zа-яА-Я\\=`ё/\*++!@#$%\^&_№?><\s]/g, "");
                    if(+v > this.maxMoney) {
                        v = this.maxMoney;
                        v = number_format(v, 0, '', ' ')

                    } else {
                        if(v !== ''){
                            v = number_format(v, 0, '', ' ')
                        }

                    }
                    this.$refs.field.value = v
                }
                if(this.mask && this.mask.mask == '9{1,2}%'){
                    if(+v !== 0){
                        v = +v;
                        this.$refs.field.value = v
                    }
                }
                this.$emit('update:modelValue', v);
                this.$emit('input');
            },
            paste(evt){
                if(this.mask != ''){
                    let v = evt.clipboardData.getData('text');
                    evt.target.value = v;
                    this.$emit('update:modelValue', evt.target.value);
                    evt.target.blur()
                    evt.target.focus()
                }
                this.$emit('paste');
            },
            enter: function () {
                this.$emit('enter');
            },
            check: function () {
                this.$emit('check')
            },
            clear: function() {
                this.$refs.field.focus();
                this.$emit('update:modelValue', '');
            },
            setFocus(value) {
                let self = this
                value = value || false;
                this.focus = value;
                this.repeatError = false;
                if(value){
                    this.$emit('focus');
                    setTimeout(function () {
                        self.showPlaceholder = true;
                    }, 50);
                } else {
                    this.$emit('blur');
                    this.showPlaceholder = false;
                }
            },
            edit: function () {
                this.$emit('edit');
            },
            inputFocus(ref) {
                if(!!this.$refs[ref]){
                    this.$nextTick(() => {this.$refs[ref].focus()});
                }
            },

        }
    }
</script>

<style>

</style>
