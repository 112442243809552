<template>
    <component :is="tag" :class="style" class="btn" :disabled="disabled">
        <slot></slot>
    </component>
</template>
<script>
    export default {
        props: {
            type:{
                default: function () {
                    return 'normal'
                }
            },
            isLoaderIn:{
                default: function () {
                    return true
                }
            },
            tag: {
                type: String,
                default: 'button'
            },
            size:  {
                type: String,
                default: 'normal' // варианты: small
            },
        },
        computed: {
            disabled(){
                return (this.type != 'normal');
            },
            style(){
                if(this.type == 'load'){
                    return 'btn--preloader '+(this.size === 'small'?'size-s':'')+(this.isLoaderIn ? 'loader-in' : '');
                }
                if(this.type == 'ok'){
                    return 'btn--preloader --ok '+(this.size === 'small'?'size-s':'')+(this.isLoaderIn ? 'loader-in' : '');
                }
            }
        },
        watch: {
            type(newVal, oldVal){
                if(newVal == 'load'){
                    setTimeout(()=>{
                        this.isLoader = true;
                    },1000);
                } else {
                    this.isLoader = false;
                }
            }
        },
        data(){
            return {
                isLoader: false
            }
        },
        methods:{
            send: function(){
            }
        }
    }
</script>
