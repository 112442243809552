<template>
    <div class="h-nav">
        <div class="item" v-for="item in menuList" :key="item.name" :class="{'active': item.code == activeCode}">
            <router-link class="h-nav-item-link h-link" :to="item.link"
                         :class="{'router-link-active': item.code == activeCode}">
                <span>{{ item.name }}<sup v-if="item.label > 0">{{ item.label }}</sup></span>
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: "SubMenuNps",
    props: {
        type: {
            default() {
                return 'public'
            },
        }
    },
    data: () => {
        return {
        }
    },
    created() {
    },
    mounted() {},
    computed: {
        ...mapState({
            menuList: state => state.menus.itemsSecondNps,
        }),
        activeCode() {
            let code = '';

            if (this.$route.name == 'dev-period' && Object.values(this.menuList)[0]) {
                code = Object.values(this.menuList)[0].code;
            }
            if (this.$route.name == 'dev-period-current' && this.$route.params.team) {
                code = this.$route.params.team;
            }

            return code;
        }
    },
    methods: {

    }
}
</script>
