<template>
    <div>
        <div class="popup__header --error">
            <div class="title">Ошибка!</div>
            <div class="text-color-white-trans text-bold mt4">Невозможно совершить действие</div>
        </div>


        <div class="popup__content">
            <div class="mt8">
                Возможные причины:<br>– Действие уже совершил другой пользователь<br>
                – У вас нет прав на это действие<br><br>
                Попробуйте перезагрузить страницу и повторить действие. Если не поможет – обратитесь в отдел разработки.
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ErrorModal",
        methods: {
        },
        data: function () {
            return {

            }
        },
        mounted() {

        },
        computed: {
        },
    }
</script>

<style scoped>

</style>
