<template>
    <tooltip
            :direction="'bottom-right-direction'"
            :add-class-wrap="'h-user'"
            :add-class-btn="'l'"
            :is-btn-close="false"
            :btn-big="true"
            :add-class="'h-user-menu'">
        <template v-slot:tooltipBtn>
            <span class="t" v-if="user.firstName">
                {{ user.firstName.charAt(0) }}
            </span>
        </template>

        <template v-slot:tooltipContent >

            <div class="h-user-menu__item df df-space-between">
                <span class="t role">
                {{user.post}} <span class="text-color-grey">{{user.groupCode}}{{user.personalNum}}</span>
                </span>
            </div>

			<div class="h-user-menu__item">
				<span class="t">{{ user.firstName }} {{ user.lastName }}</span>
            </div>

            <div class="h-user-menu__item">
                <span class="t">{{ user.phone }}</span>

            </div>
            <div class="h-user-menu__bottom df ">
                <div class="">
                    <button @click="logout()" class="btn btn--mini">
                      <span class="i-wrapper">
                        <span class="i i-exit2-mini"></span>
                      </span>
                        <span class="t">Выйти</span>
                    </button>
                </div>
            </div>
        </template>
    </tooltip>
</template>

<script>
import Tooltip from '@/components/Tooltip.vue';
import {mapState} from "vuex";
import { isMobile } from 'mobile-device-detect';

export default {
    name: "UserTooltip",
    components: {
        Tooltip,
    },
    data: function () {
        return {
            btnType: 'normal',
        }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
			popups: state => state.popups.items,
            options: state => state.options,
        }),
    },
    methods: {
        logout(){
            this.$router.push('/');
            this.$store.commit('auth/setStep',{ step:1 })
            this.$store.dispatch('auth/clearUserAuthInfo');
            this.$store.commit('menus/clearActivePath');
        },
    }
}
</script>

<style scoped>

</style>
