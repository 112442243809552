<template>

  <div id="app" :class="{'page-auth': !isUserLoaded || needAuth || isBanned || isNoSuccess || isNoSuccessSection}">

      <div v-if="!isUserLoaded" class="preloader-page">
          <div class="preloader"></div>
      </div>
      <main-section v-else></main-section>
      <div class="site-updated" v-if="popups.isNotifyReset.show">
        <div class="zems"></div>
		<div class="text-size-m mt16">
			Друзья, мы обновили сайт!<br>
			Пожалуйста, обновите страницу, чтобы изменения вступили в силу.
        </div>
        <a href="." class="btn btn--ico mt16">
            Спасибо, Екатерина!
        </a>
      </div>
    <test-notify/>
  </div>
</template>
<script>
    import {mapState} from 'vuex';
    import {mapGetters} from 'vuex';
    import MainSection from '@/views/MainSection.vue';
	import Tooltip from '@/components/Tooltip.vue';
    import TestNotify from '@/components/TestNotify.vue';
    import Popup from '@/components/modal/Popup.vue';
	import { isMobile } from 'mobile-device-detect';

    export default {
        components: {
            MainSection,
			Tooltip,
			Popup,
            TestNotify,
        },
        created() {
            this.$store.dispatch('auth/getCurrentUser').then(()=>{
            },()=>{
            });
        },
        computed: {
            ...mapState({
                isUserLoaded: state => state.auth.isLoaded,
                user: state => state.auth.user,
                formAuthFields: state => state.auth.forms.auth.fields,
                isBanned: state => state.auth.isBanned,
                isNoSuccess: state => state.auth.isNoSuccess,
                isNoSuccessSection: state => state.auth.isNoSuccessSection,
                popups: state => state.popups.items,
                localStorage: state => state.auth.localStorage,
            }),
            ...mapGetters({
                    needAuth:'auth/needAuth',
                }
            ),
        },
        mounted () {
        },
		methods: {
			openRefuseHistory(){
				this.$store.dispatch('popups/open', {name: this.popups.refuseHistory.name});
			}
		}

    }

</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
