import axios from 'axios'
import api from "@/helpers/api";
import router from "@/router";
import store from "@/store";
import App from "@/App";
const state = {
    years: [],
    yearsRepair: [],
    yearsSale: [],
    yearsFree: [],
    graph: {},
    appeals: {},
    free: {},
    objects: {},
    sale: {},
    masters: {},
    repairGraph: {},
    prepay: {},
    other: {},
    autosale: {},
    autosaleSPB: {},
    autosaleMSK: {},
    load: false,
    startUpdate: false,
    listAxiosCancelTokenSource: false,
    showSumProfitPredict: true,
}

// getters
const getters = {
}

const actions = {
    getData(context, data) {
        return new Promise((resolve, reject) => {

            context.commit('setLoad', true);

            if (context.state.load) {
                if (context.state.listAxiosCancelTokenSource !== false) {
                    context.state.listAxiosCancelTokenSource.cancel();
                    context.commit('setLoad', false);
                }
            }


            let request = axios.CancelToken.source();
            context.commit('setListAxiosCancelTokenSource', request);

            let axiosParams = api.axiosConfig();
            axiosParams.cancelToken = state.listAxiosCancelTokenSource.token;

            axios.get(process.env.VUE_APP_API_PATH+'/statistic/',axiosParams).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                } else {
                    context.commit('setData', response.data.result);
                }
                context.commit('setListAxiosCancelTokenSource', false);
                context.commit('setLoad', false);
                resolve(response.data.result);
            }).catch(function (error) {
                context.commit('setListAxiosCancelTokenSource', false);
                resolve(error);
            });
        });
    },
    postStartUpdate(context, data){
        context.commit('setStartUpdate', true);

        axios.post(process.env.VUE_APP_API_PATH + '/statistic/',{} , api.axiosConfig({})).then(response => {
            if (typeof response.data === 'object' && +response.data.error === 0) {

            } else {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
            }
        }).catch(function (error) {

        });
    },
    checkUpdate(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_PATH + '/statistic/', api.axiosConfig({
                type: 'checkUpdate',
            }))
                .then(response => {
                if (typeof response.data === 'object' && +response.data.error === 0) {
                    if(response.data.result.status === false){
                        context.commit('setStartUpdate', false);
                    }
                } else {
                    if(typeof response.data === 'object' && +response.data.error > 0) {
                        api.errorHandler(response.data.error);
                    }
                }
                resolve(response.data.result.status);
            }).catch(function (error) {
                reject();
            });
        });
    },
}

const mutations = {
    setListAxiosCancelTokenSource(state, data) {
        state.listAxiosCancelTokenSource = data;
    },
    setLoad(state, data) {
        state.load = data;
    },
    setData(state, data) {
        state.years = data.years ? data.years.sort((a, b) => a - b) : [];
        state.yearsRepair = data.yearsRepair ? data.yearsRepair.sort((a, b) => a - b) : [];
        state.yearsSale = data.yearsSale ? data.yearsSale.sort((a, b) => a - b) : [];
        state.yearsFree = data.yearsFree ? data.yearsFree.sort((a, b) => a - b) : [];
        state.graph = data.graph ? data.graph : {};
        state.appeals = data.appeals ? data.appeals : {};
        state.free = data.free ? data.free : {};
        state.objects = data.objects ? data.objects : {};
        state.sale = data.sale ? data.sale : {};
        state.masters = data.masters ? data.masters : {};
        state.repairGraph = data.repairGraph ? data.repairGraph : {};
        state.prepay = data.prepay ? data.prepay : {};
        state.other = data.other ? data.other : {};
        state.autosale = data.autosale ? data.autosale : {};
        state.autosaleSPB = data.autosaleSPB ? data.autosaleSPB : {};
        state.autosaleMSK = data.autosaleMSK ? data.autosaleMSK : {};
        state.showSumProfitPredict = data.showSumProfitPredict;
    },
    setStartUpdate(state, data) {
        state.startUpdate = data
    },
    setMastersProps(state, data) {
        for (let master in state.masters.items) {
            if(state.masters.items[master].id == data.item.id){
                for (let prop in data.props) {
                    state.masters.items[master][prop] = data.props[prop]
                }
            }

        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
