<template>
    <div class="auth-form">
        <div class="auth-form__wrap">
            <div class="text-center">
                <svg class="big-icon" style="width: 47px; height: 48px; ">
                    <use xlink:href="@/assets/img/big-icons-sprite.svg#lock2"></use>
                </svg>
				<img src="@/assets/img/zems-logo.svg" alt="" class="zems-logo-auth-form">
            </div>
            <div class="mt32 full">
                <input-styled
                              v-model="login"
                              :placeholder="'Номер телефона'"
                              :disabled="formAuthFields.login.disabled"
                              :isEdit="formAuthFields.login.disabled && step === 2"
                              @edit="edit"
                              name="login"
                              :placeholderInput="'Номер телефона'"
                              :is-block-arrow-phone="true"
                              :is-clear-btn="login.length > 0 && step === 1"
                              @enter="enter(formAuthFields.login.name)"
                              :mask="{mask:'+7(999)999-99-99',showMaskOnHover: false}"
                              :isError="formAuthFields.login.error"
                              @focus="removeError(formAuthFields.login.name)"
                              @input="removeError(formAuthFields.login.name)"
                              @paste="paste"
                              ref="loginInput"
							  type="tel"
							  inputmode="tel"
                ></input-styled>
            </div>

            <div class="mt32 full" v-if="step === 2">
                    <input-styled v-model="password"
                                  name="password"
                                  :placeholder="'Код из СМС'"
                                  :placeholderInput="'Код из СМС'"
                                  :isError="formAuthFields.password.error"
                                  :errorText="formAuthFields.password.errorText"
                                  @focus="removeError(formAuthFields.password.name)"
                                  @enter="enter(formAuthFields.password.name)"
                                  @input="removeError(formAuthFields.password.name)"
                                  ref="passwordInput"
                                  :isClearBtn="false"
								  type="tel"
								  inputmode="tel"
                    ></input-styled>
            </div>

            <div class="mt4" v-if="step === 2">
                <div class="dif text-center">
                    <div class="text-color-grey text-size-s" v-if="timer.smsBan && !formAuthFields.password.error">Новый код отправлен на указанный номер.<br>Повторный запрос возможен через {{ timer.timeBan }} ч.</div>
                    <div class="text-size-s"
                         @click="newPassword"
                         v-else-if="timer.time === 0 && !formAuthFields.password.error"><span class="link-color hover">Запросить новый</span></div>
                    <div class="text-color-grey text-size-s" v-else-if="!formAuthFields.password.error">Запросить новый через {{ timer.time }} сек.</div>
                </div>
            </div>


            <div class="mt32 text-center auth-btn">
                <submit-btn tag="button"
                            class="size-m"
                            @click.native="authorize" :type="button">
                    Продолжить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import InputStyled from '@/components/forms/InputStyled.vue'
    import { mapState } from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue'

    export default {
        name: "Auth",
        props: {

        },
        components: {
            InputStyled,
            SubmitBtn
        },
        data: function(){
            return {
                button: 'normal',
                //login: '',
            }
        },
        computed: {
            login: {
                get () {
                    return this.formAuthFields.login.value
                },
                set (value) {
                    this.$store.commit('auth/setFormAuthField', {
                        name: this.formAuthFields.login.name,
                        value:value,
                    })
                }
            },
            password: {
                get () {
                    return this.formAuthFields.password.value
                },
                set (value) {
                    this.$store.commit('auth/setFormAuthField', {
                        name: this.formAuthFields.password.name,
                        value:value,
                    })
                }
            },
            ...mapState({
                popups: state => state.popups.items,
                formAuthFields: state => state.auth.forms.auth.fields,
                timer: state => state.auth.forms.auth.timer,
                step: state => state.auth.forms.auth.step,
                isRedirect: state => state.auth.isRedirect,
            }),
        },
        mounted() {
            let self = this;
            setTimeout(function () {
                self.inputFocus('loginInput')
            },500)
        },
        methods: {
            paste(value){
            },
            edit () {
                //console.log('edit')
                this.$store.commit('auth/setFormAuthDisabled',{
                    name: this.formAuthFields.login.name,
                    value: false
                });
                this.$store.commit('auth/setStep',{ step:1 })
                this.$nextTick(function () {
                    this.inputFocus('loginInput')
                })
            },
            enter (field) {
                this.removeError(field);
                this.authorize()
            },
            newPassword () {
                this.$store.commit('auth/setFormAuthField', {
                    name: this.formAuthFields.password.name,
                    value: '',
                });
                this.$store.dispatch('auth/checkLogin',{ resend:true });
                this.inputFocus('passwordInput')
            },
            authorize () {
                let self = this;

                switch (this.step) {
                    case 1:
                        if(!this.checkLogin()){
							this.button = 'normal';
                            return false
                        }
                        this.$store.commit('auth/setFormAuthDisabled',{
                            name: this.formAuthFields.login.name,
                            value: true
                        });

                        this.$store.dispatch('auth/checkLogin',{ resend:false}).then(function () {
                            self.$store.commit('auth/setStep',{ step:2 });
                            self.$nextTick(function () {
                                self.inputFocus('passwordInput')
                            });
                        },()=>{
                            this.$store.commit('auth/setFormAuthDisabled',{
                                name: this.formAuthFields.login.name,
                                value: false
                            });
                        });
                        break;
                    case 2:
						if(!this.formAuthFields.password.error){
                            this.button = 'load';
                        }
                        if(!this.checkLogin() || !this.checkPassword()){
                            this.button = 'normal';
                            return false
                        }
                        this.$store.dispatch('auth/getToken',{isOrder:false}).then(() => {
                            this.button = 'ok';
							document.querySelector('body').classList.remove('mobile-auth');
							document.querySelector('html').classList.remove('mobile-auth');
                            this.$store.dispatch('options/getList');
                        },()=>{ this.button = 'normal'; });

                        break;
                }
            },
            inputFocus(ref) {
                if(!!this.$refs[ref]){
                    this.$refs[ref].$el.getElementsByClassName('inp-s')[0].focus()
                }
            },
            checkLogin () {
                let phone = this.formAuthFields.login.value.replace(/[^\+0-9]/g, '');
                if(phone.length < 12 || phone[2] !== '9') {
                    this.$store.commit('auth/setFormAuthError',{
                        name: this.formAuthFields.login.name,
                        value: true
                    });
                    return false;
                }
                return true;
            },
            checkPassword () {
                if(this.formAuthFields.password.value.length < 4 ) {
                    this.$store.commit('auth/setFormAuthError',{
                        name: this.formAuthFields.password.name,
                        value: true
                    });
                    return false;
                }
                return true;
            },
            removeError (field) {
                this.$store.commit('auth/setFormAuthError',{ name: field, value: false })
            },
        }
    }
</script>

<style>

</style>
