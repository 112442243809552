import { createStore } from 'vuex'
import menus from './modules/menus'
import popups from './modules/popups'
import tumblers from './modules/tumblers'
import options from './modules/options'
import masks from './modules/masks'
import auth from './modules/auth'
import requests from './modules/requests'
import applyings from './modules/applyings'
import salary from './modules/salary'
import fin from './modules/fin'
import statistic from './modules/statistic'
import staff from './modules/staff'
import createPersistedState from "vuex-persistedstate"
import configurator from './modules/configurator'

const dataState = createPersistedState({
    paths: ['auth.localStorage']
})
export default createStore({
    state: {
    },
    mutations: {
        setNotifyPageVersion(state, data){
            state.notifyPageVersion = data;
        },
    },
    actions: {
    },
    modules: {
        menus,
        popups,
        options,
        masks,
        auth,
        requests,
        salary,
        fin,
        statistic,
        staff,
		tumblers,
        applyings,
        configurator
    },
    plugins: [dataState]
})
