<template>
    <div ref="searchField" class="top-search open top-search--nps" v-clickoutside="hideSearchField">
        <div class="field-wrap">
            <input @focus="setFocus" @input="getHint()" @keyup.enter="submit" ref="searchFieldInput" type="text" class="field" placeholder="Поиск по НПС..." v-model="query" :class="{error: error}">
            <span @click="resetSearch()" class="field-clear i i-close4"></span>
            <span class="error-text" v-if="error" :class="{'field-filled': search.query.trim().length > 0}">Не менее 3 символов</span>
        </div>
        <button class="b" @click="submit"></button>
        <div id="searchFieldBtnNps" ref="searchFieldBtn" @click="openSearchField" class="btn-search"></div>
        <div class="queries" v-if="query.length > 2 && (hints.length > 0 || search.isLoadHintsSearch) && isFocus">
            <div class="preloader" v-if="search.isLoadHintsSearch && hints.length == 0"></div>
            <a @click="setQuery(hint.text)" class="query" v-for="hint in hints" href="javascript:void(0);" v-html="hint.highlight"></a>
        </div>
    </div>
</template>



<script>

    import {mapState} from 'vuex';

    export default {
        name: "SearchNps",
        components: {
        },
        data: function() {
            return {
                isOpen: false,
                isFocus: false,
                error: false,
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                search: state => state.requests.searchNps,
            }),
            query: {
                get () {
                    return this.search.query
                },
                set (value) {
                    this.$store.commit('requests/setSearchNpsQuery', value)
                }
            },
            hints(){
                let result = [];
                let hints = this.search.hints.filter(i => i.toLowerCase().indexOf(this.query.toLowerCase()) === 0);
                for(let h in hints){
                    result.push({text:hints[h],highlight:this.highlight(hints[h])})
                }
                return result;
            },
            isNPSCollect() {
                return (this.$route.name === 'nps-collect' || this.$route.name === 'nps-collect-current');
            },
            isNPSMeasure() {
                return this.$route.name === 'nps-measure' || this.$route.name === 'nps-measure-current';
            },
        },
        methods: {
            setQuery (value){
                this.$store.commit('requests/setSearchNpsQuery', value);
                this.isFocus = false;
                this.submit();
            },
            resetSearch() {
                this.$store.commit('requests/setIsSearchNpsPage', false);
                this.$nextTick(()=>{
                    this.$refs.searchFieldInput.select();
                    this.$refs.searchFieldInput.focus();
                })
                this.$store.commit('requests/clearListSearchNps');
            },
            setFocus() {
                this.isFocus = true;
                this.error = false;
            },
            hideSearchField: function() {
                this.isFocus = false;
                this.$store.commit('requests/setHintsSearch', []);
                this.$store.commit('requests/setSearchIsOpen', false);
            },
            getHint(){
                this.error = false;
                this.$nextTick(()=>{
                    if(this.query.length > 2) {
                        this.$store.dispatch('requests/getHintsNpsSearch', {npsType: this.isNPSCollect ? 'collect' : 'measure'});
                    }
                })
            },
            highlight: function(text) {
                let txt = text + '';
                return txt.replace(new RegExp(this.query, 'gi'), '<span class="text-bold">$&</span>');
            },
            submit() {
                this.error = false;
                if(this.search.query.trim().length < 3){
                    this.error = true;
                    return;
                }
                this.$store.dispatch('requests/getListSearchNps', {isNps: 'Y', npsType: this.isNPSCollect ? 'collect' : 'measure'});
            },
            openSearchField: function(){
                this.$nextTick(()=>{
                    this.$refs.searchFieldInput.select();
                    this.$refs.searchFieldInput.focus();
                })
                setTimeout(()=>{
                    this.isOpen = true;
                },300)
                this.getHint(false);
            },
        },
        created: function() {
            document.addEventListener('keyup', e => {
                if(e.keyCode === 13){
                    this.submit();
                }
            });
            if(this.$route.query.search !== undefined && this.$route.query.search.length > 0){
                this.setQuery(this.$route.query.search);
                this.submit();
            }
        },
        directives: {
            'clickoutside': {
                mounted: function(el, binding, vNode) {
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
                        //console.warn(warn);
                    }
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler;
                    document.addEventListener('mousedown', handler);
                },

                unmounted: function(el, binding) {
                    document.removeEventListener('mousedown', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null;
                }
            }
        }
    }
</script>
<style scoped>
    .header-main .header-category__search .field {
        width: 225px;
    }
</style>
