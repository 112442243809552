import axios from 'axios'
import api from "@/helpers/api";

const state = {
    currentItem: null,
    haveFieldError: false,
    config: null,
    isEditing: false,
    request_id: null,
    token: process.env.VUE_APP_ZEMSCOMPLECT_TOKEN,
    isConfigLoading: false,
    isConfigEdited: false,
    step: 1,
    features: null,
}   

// getters
const getters = {
    roomGroupsFiltered(state) {
        return state.config?.room_groups?.map(category => {
            let newCategory = {...category};
            newCategory.rooms = newCategory.rooms.filter(room => room.client_rooms?.length);
            return newCategory.rooms.length > 0 ? newCategory : null;
        }).filter(category => category !== null);
    },
    finalConfig(state, getters) {
        let obj =  {
            request_id: state.request_id,
            is_moscow: state.currentItem?.props?.is_msk=='Y',
            no_room: state.features,
            room_groups: getters.roomGroupsFiltered,
        }
        return obj;
    }
}

const actions = {
    removeField(context, { category, room, field}) {
        let roomIndex = category.rooms.indexOf(room);
        let fieldIndex = category.rooms[roomIndex].client_rooms.indexOf(field);
        category.rooms[roomIndex].client_rooms.splice(fieldIndex, 1);
    },
    validate({ commit, dispatch }) {
        let error = false;
        let haveField = false;
    
        for (let { rooms } of Object.values(state.config.room_groups)) {
            for (let { client_rooms } of Object.values(rooms)) {
                if (client_rooms) {
                    for (let field of Object.values(client_rooms)) {
                        haveField = true;
                        // Check if the room has a name
                        if (!field.name.length) {
                            error = true;
                            dispatch('setError', field.id);
                        } 
    
                        // Check if there is an active checkbox and if the select is active
                        for (let conf of field.materials_for_room) {
                            if (conf.material_selector_id !== undefined && !conf.material_selector_id && conf.material_selector_id !== 0) {
                                error = true;
                                dispatch('setConfError', `${field.id}${conf.material_type_id}tumbler`);
                            }
                        }
                    }
                }
            }
        }
    
        commit('setHaveFieldError', !haveField);
    
        if (!haveField) {
            error = true;
        }
    
        setTimeout(() => {
            dispatch('scrollToError');
        });
    
        return !error;
    },
    

    scrollToError() {
        const errorInputs = document.getElementsByClassName('--error');
        const scrollOptions = {
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        };
        const headerHeight = 64;
        if (errorInputs.length > 0) {
            window.scrollTo({
                top: errorInputs[0].getBoundingClientRect().top + window.scrollY - headerHeight,
                ...scrollOptions,
            });
        }
    },
    check({ dispatch, commit }) {
        dispatch('validate').then((valid) => {
            if (valid) {
                commit('setStep', 2);
            }
        });
    },
    getConfig(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_PATH_CONFIG+'/api/specification_form',api.axiosConfig({ token: state.token })).then(response => {
                context.commit('setConfig', response.data);
                context.commit('setIsEditing', false);
                context.commit('setStep', 1);
                resolve();
            }).catch(function (error) {
                resolve();
            });
        });
    },
    getItemConfig(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_PATH_CONFIG}/api/specification_order/${state.request_id}`,api.axiosConfig({token: state.token})).then(response => {
                // мердж конфига комнат в конфиг стандартны
                if ( response.data.request_id) {
                    context.commit('setRequestId', response.data.request_id);
                    // мердж room_groups
                    context.commit('mergeArrays', response.data);

                    // мердж no_room
                    context.dispatch('mergeFeatures', response.data.no_room);

                    setTimeout(() => {
                        context.commit('setIsEditing', true);
                    });


                }
                resolve();
            }).catch(function (error) {
                resolve();
            });
        });
    },
    send({ getters, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_PATH_CONFIG}/api/specification_order`, getters.finalConfig, api.axiosConfig({ token: state.token })).then(response => {
                dispatch('requests/actionRequest', {
                    action: 'DrawingSetSpecification',
                    id: getters.finalConfig.request_id,
                    params: {
                        specification_id: response.data?.result?.id,
                    }
                }, {root: true}).then((res)=>{
                    resolve(res);
                });
            }).catch(function (error) {
                resolve();
            });
        });
    },
    save({ commit, getters }) {
        return new Promise((resolve, reject) => {
            axios.put(`${process.env.VUE_APP_PATH_CONFIG}/api/specification_order/${state.request_id}`, getters.finalConfig, api.axiosConfig({ token: state.token })).then(response => {
                commit('setIsAutoSaving', 'ok');
                setTimeout(() => {
                    commit('setIsAutoSaving', 'none');
                }, 3000);
                resolve();
            }).catch(function (error) {
                resolve();
            });
        });
    },
    setError(context, data) {
        let fieldWrapper = document.getElementById(`${data}-input`);
        let field = fieldWrapper.getElementsByTagName('input')[0];
        field.classList.remove('repeat-error');
        if (field.classList.contains('error')) {
            setTimeout(() => {field.classList.add('repeat-error')}, 0);
        } else {
            field.classList.add('error');
            fieldWrapper.classList.add('--error');
        }
    },
    setConfError(context, data) {
        let field = document.getElementById(data);
        field.classList.remove('repeat-error');
        if (field.classList.contains('--error')) {
            setTimeout(() => {field.classList.add('repeat-error')}, 0);
        } else {
            field.classList.add('--error');
            field.classList.add('error');
        }
    },
    mergeFeatures({ commit, state }, arr) {
        state.config.no_room.forEach(mainItem => {
            mainItem.materials.forEach(material => {
                // Check if material_type_id is in the update array
                const materialTypeIdToUpdate = arr.find(updateItem => updateItem.material_type_id === material.material_type_id);

                // Check if material_selector_id is in the update array for selectors
                if (material.selectors.length) {
                    material.selectors.forEach(selector => {
                        const materialSelectorIdToUpdate = arr.find(item => item.material_selector_id === selector.id);
                        selector.checked = materialSelectorIdToUpdate ? 1 : 0;
                    });
                }

                // Update the checked property based on the conditions
                material.checked = materialTypeIdToUpdate ? 1 : 0;
            });
        });
    },
}

const mutations = {
    setCurrentItem(state, data) {
        state.currentItem = data;
    },
    setHaveFieldError(state, data) {
        state.haveFieldError = data;
    },
    addField(state, payload) {
        const { room } = payload;
        const field = {
            name: room.name,
            id: `${room.id}${room.client_rooms?.length ?? 0}`,
            materials_for_room: [],
        }
        if (room.client_rooms?.length < 9) {
            room.client_rooms.push(field);
        } else if (room.client_rooms === undefined){
            room.client_rooms = [field];
        }
    },
    setStep(state, data) {
        state.step = data;
    },
    setConfig(state, data) {
        state.config = data;
    },
    setRequestId(state, data) {
        state.request_id = data;
    },
    mergeArrays(state, data) {
        const defaultArray = state.config.room_groups;
        const updatedArray = data.room_groups;
        const defaultMap = new Map(defaultArray.map(group => [group.id, group]));

        for (const updatedGroup of updatedArray) {
          const groupId = updatedGroup.id;

          if (defaultMap.has(groupId)) {
            const defaultGroup = defaultMap.get(groupId);
      
            for (const updatedRoom of updatedGroup.rooms) {
              const roomId = updatedRoom.id;

              let roomGroupIndex = defaultGroup.rooms.findIndex(obj => obj.id === roomId);
              if ("client_rooms" in updatedRoom) {
                for (const clientRoom of updatedRoom.client_rooms) {
                    clientRoom.id = clientRoom.id.toString();
                }
                defaultGroup.rooms[roomGroupIndex].client_rooms = updatedRoom.client_rooms;
              }
            }
          }
        }
    },
    updateFeatureValue(state, { id, value }) {
        if (state.features[id]) {
            state.features[id].value = value;
        }
    },
    setIsEditing(state, data) {
        state.isEditing = data;
    },
    setIsAutoSaving(state, data) {
        state.isAutoSaving = data;
    },
    setFeatures(state, data) {
        state.features = data;
    },
    setIsConfigEdited(state, data) {
        state.isConfigEdited = data;
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
