/**
 * Это модуль для авторизации
 * */

import axios from 'axios'
import api from '@/helpers/api'
import Vue from 'vue'
import store from "@/store";

const state = {
    forms: {
        auth: {
            fields: {
                login: {
                    name: 'login',
                    value: '',
                    required: true,
                    error: false,
                    errorText: 'Некорректный номер',
                    disabled: false,
                    focus: false,
                },
                password: {
                    name: 'password',
                    value: '',
                    required: true,
                    error: false,
                    errorText: 'Неверный код',
                    disabled: false,
                    focus: false,
                }
            },
            step: 1,
            timer: {
                time: 0,
                timeDefault: 30,
                intervalId: [],
                smsBan: false,
                timeBan: 0
            }
        },
    },
    user: {},
    isLoaded: false,
    isBanned: false,
    isNoSuccess: false,
    isNoSuccessSection: false,
    isGetRefreshToken: false,
    localStorage: {
        token: false,
        refresh_token: false,
    }
}

// getters
const getters = {
    token(){
        return store.state.auth.localStorage.token;
    },
    refresh_token(){
        return store.state.auth.localStorage.refresh_token;
    },
    needAuth(){
        return (!store.state.auth.localStorage.token || !store.state.auth.user) && store.state.auth.isLoaded;
    },
}

const actions = {
    startTimer(context, data) {
        if(state.forms.auth.timer.intervalId.length == 1 && state.forms.auth.timer.time == 0 || state.forms.auth.timer.intervalId.length > 1) {
            context.commit('clearInterval');
        }

        if(state.forms.auth.timer.intervalId.length == 0) {
            let time = state.forms.auth.timer.timeDefault;
            if(!!data && data.time) {
                time = data.time;
            }

            context.commit('setTimerTime', time);
            let timer = setInterval(function () {
                if(+state.forms.auth.timer.time > 0) {
                    context.commit('decrementTimerTime');
                } else {
                    context.commit('clearInterval');
                }
            }, 1000);
            context.commit('setInterval',timer);
        }

    },
    getCurrentUser(context, data) {
        return new Promise((resolve, reject) => {
            if(context.state.localStorage.token) {
                axios.get(process.env.VUE_APP_API_PATH+'/users/', api.axiosConfig()).then(response => {
                    if(typeof response.data === 'object' && +response.data.error === 0) {
                        if(response.data.result.user) {
                            context.commit('setUser', response.data.result);
                        } else {
                            context.dispatch('clearUserAuthInfo', false);
                        }
                        context.commit('setLoaded', true);
                        resolve();
                    } else {
                        api.errorHandler(response.data.error).then(() => {reject()});
                    }
                }).catch(function (error) {
                    resolve();
                });
            } else if(context.state.localStorage.refresh_token){
                context.dispatch('checkRefreshToken', false).then(()=>{
                    resolve();
                });
            } else {
                context.commit('setLoaded', true);
                resolve();
            }
        });
    },
    checkLogin(context, data) {
        return new Promise((resolve, reject) => {
            let params = {
                login: context.state.forms.auth.fields.login.value,
            };
            axios.post(process.env.VUE_APP_API_PATH+'/auth/',{}, api.axiosConfigNoAuth(params))
                .then(response => {
                    if(typeof response.data === 'object' && +response.data.error === 0) {
                        if(response.data.result.ban !== undefined && response.data.result.ban.banType === 'sms') {
                            context.commit('setSmsBan',response.data.result.ban);
                        } else {
                            if(!!response.data.result.threshold && response.data.result.threshold.time > 0){
                                context.dispatch('startTimer',{time:+response.data.result.threshold.time});
                            } else {
                                context.dispatch('startTimer',{time: context.state.forms.auth.timer.timeDefault});
                            }
                        }
                        resolve();
                    } else if(response.data.error > 0) {
                        api.errorHandler(response.data.error).then(() => {reject()});
                    }
                }).catch(function (error) {
                    reject();
                });
        });
    },
    checkRefreshToken(context, data){
        return new Promise((resolve, reject) => {
            if(!store.isGetRefreshToken && context.state.localStorage.refresh_token){
                context.commit('setIsGetRefreshToken',true);
                if(context.state.localStorage.refresh_token){
                    let params = new FormData();
                    resolve();
                    params.append('refresh_token', context.state.localStorage.refresh_token);
                    context.dispatch('getTokenRequest',{params:params}).then(
                        ()=>{ context.commit('setIsGetRefreshToken',false); resolve() },
                        (error)=>{ context.commit('setIsGetRefreshToken',false);reject(error) }
                    );
                } else {
                    resolve()
                }
            }
        })
    },
    getToken(context, data){
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.append('login', context.state.forms.auth.fields.login.value);
            params.append('password', context.state.forms.auth.fields.password.value);
            context.dispatch('getTokenRequest',{params:params}).then(
                ()=>{ resolve() },
                (error)=>{ reject(error) }
            );
        });
    },
    setRole(context, data){
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.append('role', data.role);
            axios.post(process.env.VUE_APP_API_PATH+'/users/',params, api.axiosConfig())
                .then(response => {
                    if(+response.data.error === 0) {
                        resolve();
                    } else if(response.data.error > 0) {
                        api.errorHandler(response.data.error).then(() => {reject()});
                    }
                }).catch(function (error) {
                reject();
            });
        });
    },
    getTokenRequest(context, data){
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_PATH+'/auth/',data.params)
            .then(response => {
                if(typeof response.data === 'object' && +response.data.error === 0) {
                    if(response.data.result.token){
                        context.commit('setLocalStorage',{key:'token', value: response.data.result.token})
                        context.commit('setLocalStorage',{key:'refresh_token', value: response.data.result.refreshToken})
                        if(response.data.result.user){
                            context.commit('setUser', response.data.result);
                            context.commit('setLoaded', true);
                            context.commit('clearInterval');
                        }
                        if(!data.params.refresh_token){
                            context.commit('setFormAuthField', {name:'password',value: ''});
                        }
                        resolve();
                    }
                } else if(response.data.error > 0) {
                    api.errorHandler(response.data.error);
                    reject();
                }
            }).catch(function (error) {});
        });
    },

    logout(context,data) {
        context.commit('setLocalStorage',{key:'token', value: false})
        context.commit('setLocalStorage',{key:'refresh_token', value: false})
        context.commit('setUser', {user:{}});
        context.commit('setFormAuthField', {
            name: 'login',
            value:'',
        })
        context.commit('setFormAuthDisabled',{
            name: 'login',
            value: false
        });
        context.commit('setStep',{ step:1 })
        context.commit('clearForms')
        window.scroll(0,0);
    },
    clearUserAuthInfo(context) {
        context.commit('setLocalStorage',{key:'token', value: false})
        context.commit('setLocalStorage',{key:'refresh_token', value: false})
        context.commit('setUser', {user:{}});
        context.commit('clearForms');
    }
}

const mutations = {
    setFormAuthField(state, data) {
        if(state.forms.auth.fields.hasOwnProperty(data.name)) {
            state.forms.auth.fields[data.name].value = data.value
        }
    },
    setFormAuthError(state, data) {
        if(state.forms.auth.fields.hasOwnProperty(data.name)) {
            if(state.forms.auth.fields[data.name].error && data.value == true){
                state.forms.auth.fields[data.name].error = false;
                setTimeout(() => {
                    state.forms.auth.fields[data.name].error = true;
                });
            }else{
                state.forms.auth.fields[data.name].error = data.value;
            }
        }
    },
    setFormAuthDisabled(state, data) {
        if(state.forms.auth.fields.hasOwnProperty(data.name)) {
            state.forms.auth.fields[data.name].disabled = data.value
        }
    },
    setFormAuthFocus(state, data) {
        if(state.forms.auth.fields.hasOwnProperty(data.name)) {
            state.forms.auth.fields[data.name].focus = data.value
        }
    },
    setFormRegistrationField(state, data) {
        if(state.forms.registration.fields.hasOwnProperty(data.name)) {
            state.forms.registration.fields[data.name].value = data.value
        }
    },
    setFormRegistrationError(state, data) {
        if(state.forms.registration.fields.hasOwnProperty(data.name)) {
            state.forms.registration.fields[data.name].error = data.value
        }
    },
    setFormRegistrationDisabled(state, data) {
        if(state.forms.registration.fields.hasOwnProperty(data.name)) {
            state.forms.registration.fields[data.name].disabled = data.value
        }
    },
    setTimerTime(state, data) {
        state.forms.auth.timer.time = data
    },
    decrementTimerTime(state) {
        if(+state.forms.auth.timer.time > 0){
            state.forms.auth.timer.time--
        }
    },
    clearInterval(state) {
        for(let i in state.forms.auth.timer.intervalId){
            clearInterval(state.forms.auth.timer.intervalId[i]);
        }
        state.forms.auth.timer.intervalId = []
    },
    setInterval(state, data) {
        state.forms.auth.timer.intervalId.push(data)
    },
    setStep(state, data) {
        if(!!data && data.step > 0){
            state.forms.auth.step = data.step
        }
    },
    setIsValidPhone(state, data) {
        state.isValidPhone = data;
    },
    setBan(state, data) {
        state.isBanned = data
    },
    setBanPermanent(state, data) {
        state.isBannedPermanent = data
    },
    setIsNoSuccess(state, data) {
        state.isNoSuccess = data
    },
    setIsNoSuccessSection(state, data) {
        state.isNoSuccessSection = data
    },
    setIsRedirect(state, data) {
        state.isRedirect = data
    },
    setSmsBan(state, data){
        state.forms.auth.timer.smsBan = true;
        state.forms.auth.timer.timeBan = data.bannedTime;
    },
    setIsGetRefreshToken(state, data){
        state.isGetRefreshToken = data;
    },
    clearForms(state){
        state.forms =  {
            auth: {
                fields: {
                    login: {
                        name: 'login',
                        value: '',
                        required: true,
                        error: false,
                        errorText: 'Некорректный номер',
                        disabled: false
                    },
                    password: {
                        name: 'password',
                        value: '',
                        required: true,
                        error: false,
                        errorText: 'Неверный код',
                        disabled: false
                    }
                },
                step: 1,
                timer: {
                    time: 0,
                    timeDefault: 30,
                    intervalId: [],
                    smsBan: false,
                    timeBan: 0
                }
            },
        };
    },
    setUser(state, data) {
        if(typeof data === 'object' && data.user !== undefined) {
            state.user = data.user;
        }
    },
    setLoaded(state, data) {
        state.isLoaded = data;
    },
    setLocalStorage(state, data) {
        state.localStorage[data.key] = data.value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
