<template>
  <footer class="footer">
    <div class="content">
      <div class="footer-copyright">
        <div class="icon"></div>
        <span>© 1980-2092&nbsp;</span>Алексей Земсков
      </div>

      <div class="public-offer" v-if="!hideOffer">
        <a href="javascript:void(0);" @click="openPublicOffer">Публичная оферта</a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        name: "VueFooter",
        props: {
            hideOffer: {
                default: false
            },
            showLogo: {
                default: false
            }
        },
        computed: {
          ...mapState({
              popups: state => state.popups.items,
          }),
        },
        methods: {
          openPublicOffer: function () {
              this.$store.dispatch('popups/open', {name: this.popups.publicOffer.name});
          },
        }
    }
</script>

<style>

</style>
