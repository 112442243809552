import axios from 'axios'
import api from "@/helpers/api";

const state = {
    items: [],
    isLoadNextPage: true,
    page: 1,
    isLoad: false,
    loadedItemsId: [],
    status: {
        new: {
            code: 'applyings_new',
        },
        created: {
            code: 'applyings_created',
        },
        link: {
            code: 'applyings_link',
        },
        rejected: {
            code: 'applyings_rejected',
        },
        think: {
            code: 'applyings_think',
        },
    }
}

// getters
const getters = {
    isStatusNew: (state) => (item) => {
        return item.props.status_code === state.status.new.code;
    },
    isStatusCreated: (state) => (item) => {
        return item.props.status_code === state.status.created.code;
    },
    isStatusLink: (state) => (item) => {
        return item.props.status_code === state.status.link.code;
    },
    isStatusRejected: (state) => (item) => {
        return item.props.status_code === state.status.rejected.code;
    },
    isStatusThink: (state) => (item) => {
        return item.props.status_code === state.status.think.code;
    }
}

const actions = {
    getList(context, data) {
        return new Promise((resolve, reject) => {

            let page = 1;
            if (data.page) {
                page = data.page;
            } else {
                context.commit('isLoadNextPage', true);
                context.commit('clearList');
                context.commit('setPage', 1);
            }

            if (context.state.isLoad && page === 1) {
                if (context.state.listAxiosCancelTokenSource !== false) {
                    context.state.listAxiosCancelTokenSource.cancel();
                    context.commit('setLoad', false);
                }
            }
   
            if(context.state.isLoadNextPage && !context.state.isLoad){
                context.commit('setLoad', true);

                let request = axios.CancelToken.source();
                context.commit('setListAxiosCancelTokenSource', request);

                let par = {
                    status: data.status,
                    additionalElement: data.additionalElement,
                    page: page,
                }
                if(data.action){
                    par.action = data.action;
                }
                if(data.team){
                    par.team = data.team;
                    par.pause = data.pause;
                }

                let axiosParams = api.axiosConfig(par);

                axiosParams.cancelToken = state.listAxiosCancelTokenSource.token;
                let params = new FormData();
                params.append('filterId', context.state.loadedItemsId.join(','));
                axios.post(process.env.VUE_APP_API_PATH + '/applyings/',params, axiosParams).then(response => {

                    context.commit('setListAxiosCancelTokenSource', false);

                    if (typeof response.data === 'object' && +response.data.error > 0) {
                        api.errorHandler(response.data.error);
                    }
                    if(typeof(response.data.result.isLoadNextPage) !== 'undefined'){
                        context.commit('isLoadNextPage', response.data.result.isLoadNextPage);
                    } else {
                        if (typeof(response.data.result.items) !== 'undefined' && response.data.result.items.length) {
                            context.commit('isLoadNextPage', true);
                        } else {
                            context.commit('isLoadNextPage', false);
                        }
                    }

                    context.commit('setList', response.data.result);
                    context.commit('incrementPage');
                    context.commit('setLoad', false);
                    resolve(response.data.result);
                }).catch((error) => {
                    resolve([]);
                });
            } else {
                resolve([]);
            }


        }).catch(function (error) {
            context.commit('setListAxiosCancelTokenSource', false);
            resolve();
        });
    },
    actionRequest(context, data) {
        return new Promise((resolve, reject) => {
            let params = new FormData();
            for(let name in data.params){
                params.append(name, data.params[name]);
            }
            axios.post(process.env.VUE_APP_API_PATH + '/applyings/'+data.id+'/', params, api.axiosConfig({
                action: data.action,
            })).then(response => {
                if (typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                    reject();
                } else {
                    if(!response.data.result.allowed) {
                        context.dispatch('popups/open', {name: 'errorModal'},{root:true});
                    }
                    resolve(response.data.result);
                }
            });
        }).catch(function (error) {
            resolve();
        });
    },
    getItem(context, data) {
        return new Promise((resolve, reject) => {
            resolve();
        });
    },
}

const mutations = {
    setListAxiosCancelTokenSource(state, data) {
        state.listAxiosCancelTokenSource = data;
    },
    setList(state, data) {
        if (typeof data === 'object') {
            if (state.page > 1) {
                for (let i in data.items) {
                    state.items.push(data.items[i]);
                }
            } else {
                state.items = data.items;
            }
        } else {
            state.items = [];
        }

        state.loadedItemsId = [];
        for(let i in state.items) {
            state.loadedItemsId.push(state.items[i].id);
        }
    },
    setLoad(state, data) {
        state.isLoad = data;
    },
    setItem(state, data){
        let item = data.returnItem;
        for (let prop in state.items) {
            if(state.items[prop].id == item.id) {
                state.items[prop] = item;
            }
        }
    },
    isLoadNextPage(state, data) {
        state.isLoadNextPage = data;
    },
    clearList(state) {
        state.items = [];
        state.loadedItemsId = [];
    },
    setPage(state, data) {
        state.page = data;
    },
    incrementPage(state) {
        state.page++;
    },
    deleteRequest(state, data) {
        for (let prop in state.items) {
            if(state.items[prop].id == data.id) {
                state.items.splice(prop, 1);
            }
        }
    },
    setItemStatusComment(state, data) {
        data.item.status.comment = data.comment
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
