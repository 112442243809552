<template>
    <div class="textarea-field" ref="inpWrap" :class="{'focused': this.focus, fill: filled}">
        <div class="label">{{label}}</div>
        <textarea-autosize :class="classTextareaField"
                  :id="inputId"
                  ref="textField"
                  :auto-focus="autoFocus"
                  @keyup.enter="enter()"
                  @focus.native="setFocus(true)"
                  @blur.native="setFocus(false)"
                  :disabled="disabled"
                   :min-height="minHeight"
                  v-model="inputVal"
                  :style="addStyle"
                  @input="updateValue"
                  :placeholder="placeholder"
                  :autoResizeOnFocus="autoResizeOnFocus"
                  :resizable="resize"></textarea-autosize>
        <div v-if="isError && errorText" class="textarea-error" :class="{'repeat-error': repeatError}">{{ errorText }}</div>
    </div>
</template>

<script>
    import TextareaAutosize from '@/components/forms/TextareaAutosize.vue'
    export default {
        components: {
            TextareaAutosize
        },
        name: "TextareaField",
        props: {
            autoResizeOnFocus: {default: true},
            modelValue: {default: ''},
            placeholder: {default: ''},
            label: {default: ''},
            inputId: {default: ''},
            disabled: {default: false},
            isError: {default: false},
            isEdit: {default: false},
            addStyle: {default: ''},
            autoFocus: {default: false},
            isValidate: {default: false},
            errorText: {default: 'Не менее 10-ти символов'},
            resize: {default: false},
            minHeight: {
                type: [Number],
                'default': null
            },
            classTextarea: {
                default(){
                    return []
                },
                type: Array,
            },
            hasCounterSMS: {default: false},
        },
        data: function () {
            return {
                focus: false,
                counterSMS: 0,
                counterSimbols: 0,
                counterSmsLength: 70,
                repeatError: false,
            }
        },
        watch:{
            isError(val,oldVal){
                if(this.isError){
                    if(this.$el.closest('.popup-wrapper')){
                        let $top = 0;
						let pr = this.$el.closest('.popup-wrapper');
                        $top += parseFloat(this.$refs.inpWrap.getBoundingClientRect().top + this.$el.closest('.popup-wrapper').scrollTop) - 10;
                        pr.scrollTo({top: $top, left: 0, behavior: 'smooth'});
                    }
                }
                if(oldVal){
                    this.repeatError = true;
                }
            },
        },
        computed: {
            filled(){
                return this.modelValue.toString().length > 0;
            },
            showLabel() {
                return this.focus || this.inputVal.toString().length > 0
            },
            classTextareaField() {
                return { error: this.isError, 'repeat-error': this.repeatError, ...this.classTextarea }
            },
            inputVal:{
                get() { return this.modelValue },
                set(value) { this.$emit('update:modelValue', value)},
            }
        },
        mounted() {

        },
        emits: ['input', 'enter', 'check', 'focus', 'blur', 'edit', 'update:modelValue'],
        methods: {
            updateValue: function (value) {
                this.$emit('update:modelValue', value);
            },
            enter: function () {
                this.$emit('enter')
            },
            check: function () {
                this.$emit('check')
            },
            setFocus(value) {
                let self = this
                value = value || false;
                this.repeatError = false;
                this.focus = value
                if(value){
                    this.$emit('focus');
                } else {
                    this.$emit('blur');
                }
            },
            edit: function () {
                this.$emit('edit');
            },
        },
    }
</script>

<style>

</style>
