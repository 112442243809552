<template>
    <div v-if="template === 'master'" class="h-nav">
        <div class="h-nav-item" v-for="item in selectedSubmenu" :key="item.name" :class="{'active': item.code == selectedItem}">
            <router-link class="h-nav-item-link h-link" :to="item.link"
                         :class="{'router-link-active': item.code == selectedItem}">
                <span>{{ item.name }}<sup v-if="showLabel(item)">{{ item.label }}</sup></span>
            </router-link>
        </div>
    </div>
    <div v-else>
        <div class="h-nav">
            <div class="item" v-for="item in selectedSubmenu">
                <router-link :class="{'router-link-exact-active router-link-active active': selectedItem == item.code || $route.params.status == 'new'}"
                             :to="item.link">
                    <span>{{ item.name }}<sup v-if="showLabel(item) && item.code !== 'payout'">{{ item.label }}</sup></span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "SubMenu",
        props: {
            template: {
                default: ''
            }
        },
        computed: {
            ...mapState({
                menuList: state => state.menus.itemsTop,
                menuSecond: state => state.menus.itemsSecond,
            }),
            type() {
                let code = '';

                if (this.$route) {
                    code = this.$route.path;
                }

                if (typeof code !== 'undefined') {
                    let arPath = code.split('/');
                    let isSuccess = false;
                    for(let i in this.menuList){
                        if(arPath[1] == this.menuList[i].code){
                            isSuccess = true;
                        }
                    }
                    if(isSuccess){
                        code = arPath[1];
                    } else {
                        code = false;
                    }
                }

                if (!code && Object.values(this.menuList)[0]) {
                    code = Object.values(this.menuList)[0].code;
                }

                return code;
            },
            selectedItem() {
                let code = '';

                if (this.$route) {
                    code = this.$route.path;

                    if (code === '/' || code === '') {
                        code = Object.values(Object.values(this.menuSecond)[0])[0].code;
                    }
                }


                let isSuccess = true;
                if (typeof code !== 'undefined') {
                    isSuccess = false;
                    let arPath = code.split('/');
                    for(let i in this.selectedSubmenu){
                        if(arPath[2] == this.selectedSubmenu[i].code){
                            isSuccess = true;
                        }
                    }
                    if(isSuccess){
                        code = arPath[2];
                    } else {
                        code = false;
                    }
                }

                if (!code && Object.values(this.selectedSubmenu)[0]) {
                    code = Object.values(this.selectedSubmenu)[0].code;

                    if (this.type === 'salaries') {
                        if (Object.values(this.selectedSubmenu)[1].label > 0) {
                            code = Object.values(this.selectedSubmenu)[1].code;
                        }
                    }

                    if(!isSuccess && this.$route.path != '/'+this.type+'/'+code+'/' && this.$route.path != '/' && this.$route.name != 'search' && this.$route.path.indexOf('/buh') != -1){
                        this.$router.push('/'+this.type+'/'+code+'/');
                    }
                }

                return code;
            },
            selectedSubmenu(){
                return this.menuSecond[this.type];
            }
        },
        methods:{
            showLabel: function (item) {
                return this.selectedItem !== item.code && this.$route.params.status !== 'new' && item.label > 0;
            }
        }
    }
</script>
