<template>
    <div>
        <div class="popup__header">
            <div class="title">Сменить роль</div>
            <div class="text-color-white-trans text-bold mt4">{{ user.firstName }} {{ user.lastName }}</div>
        </div>


        <div class="popup__content">
            <div class="text-bold">
                Выберите роль, под которой вы хотите авторизоваться:
            </div>
            <div class="dif mt16">
                <tumbler @change="removeError(form.option)" :options="options" :elem-width="93" v-model="form.option.value" :error="form.option.error"/>
            </div>

			<div class="roles" v-if="false">
				<div class="role mt32" v-for="i in 3">
					<div class="text-bold">
						Руководители
					</div>
					<div class="worker mt8" v-for="i in 3" :class="{choosed: false}">
						<div class="l">
							<div class="member r">
								r1
							</div>
							<div class="name">
								Андрей Поляков
							</div>
						</div>
						<div class="r team">
							Ком-3
						</div>
					</div>
				</div>
			</div>

            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue'
    export default {
        name: "ChangeRole",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                if(this.user.changeRole == this.form.option.value.id || this.user.groupCode == this.form.option.value.groupCode){
                    this.$store.commit('popups/close', {name: this.popups.changeRole.name});
                } else {
                    this.btnType = 'load';
                    this.$store.dispatch('auth/setRole',{role:this.form.option.value.groupCode}).then(() => {
                        this.btnType = 'ok';
                        setTimeout(()=>{
                            this.btnType = 'normal';
                            this.$store.commit('popups/close', {name: this.popups.changeRole.name});
                            window.location.reload();
                        }, this.optionsMain.delayPopupClose);
                    },()=>{ this.btnType = 'normal'; });
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    option: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                },
				options: [
					{id: 36, name: 'Директор', type: 'role', groupCode: 'D'},
					{id: 42, name: 'Мастер', type: 'role', groupCode: 'M'},
					//{id: 3, name: 'сотрудники', type: 'user'}

				]
            }
        },
        mounted() {
            if(this.user.groupCode == 'D' && !this.user.changeRole || this.user.changeRole  == 'D'){
                this.form.option.value = this.options[0];
            } else {
                this.form.option.value = this.options[1];
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                user: state => state.auth.user,
                optionsMain: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
