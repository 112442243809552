import axios from 'axios';
import api from '@/helpers/api';

const state = {
    items: [],
    isLoadNextPage: true,
    page: 1,
    isLoad: false,
    loadedItemsId: [],
    workers:{
        technologists: [],
        inspectors: [],
    },
    allWorkers:{
        coordinators: [], // Координатор
        measurers: [], // Замерщик
        leaders: [], // Руководитель
        assistants: [], // Ассистент руководителя
    },
    listAxiosCancelTokenSource: false,
    search: {
        query: '',
        isLoadHintsSearch: false,
        sections: [],
        sectionsForbidden: [],
        hints: [],
        isEmpty: true,
        isLoadSearch: false,
        isSearchPage: false,
		isOpen: false,
    },
    searchNps: {
        query: '',
        isLoadHintsSearch: false,
        items: [],
        hints: [],
        isEmpty: true,
        isLoadSearch: false,
        isSearchPage: false,
        isOpen: false,
    },
    previousSections: {
        top: false,
        sub: false,
    },
    devPeriodPause: false,
    npsRate: {
        'nps-measure-all': 0,
        'nps-measure-promo': 0,
        'nps-measure-neutral': 0,
        'nps-measure-critic': 0,
    },
}

// getters
const getters = {
}

const actions = {
    sendLog(context, data) {
        //send log
        function browser() {
            let ua = navigator.userAgent, tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE ' + (tem[1] || '');
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
            return M.join(' ');
        }

        let axiosParams = api.axiosConfig({
            additionalElement: data.additionalElement,
        });

        let params = new FormData();

        params.append('page', window.location.href);
        params.append('pageNumber', state.page);
        params.append('vuex', window.localStorage.getItem('vuex'));
        params.append('clientTime', new Date());
        params.append('browser', browser());

        for(let code in data) {
            if (typeof(data[code]) === "object") {
                params.append(code, JSON.stringify(data[code]));
            } else {
                params.append(code, data[code]);
            }
        }

        axios.post(process.env.VUE_APP_API_PATH + '/logger/',params, axiosParams);

        //end send log
    },
    getList(context, data) {
        return new Promise((resolve, reject) => {
            let page = 1;
            if (data.page) {
                page = data.page;
            } else {
                context.commit('isLoadNextPage', true);
                context.commit('clearList');
                context.commit('setPage', 1);
            }

            if (context.state.isLoad && page === 1) {
                if (context.state.listAxiosCancelTokenSource !== false) {
                    context.state.listAxiosCancelTokenSource.cancel();
                    context.commit('setLoad', false);
                }
            }

            if(state.isLoadNextPage && !context.state.isLoad){
                context.commit('setLoad', true);

                let request = axios.CancelToken.source();
                context.commit('setListAxiosCancelTokenSource', request);

                let par = {
                    status: data.status,
                    additionalElement: data.additionalElement,
                    page: page,
                }
                if(data.action){
                    par.action = data.action;
                }
                if(data.team){
                    par.team = data.team;
                    par.pause = data.pause;
                }
                if(data.manager){
                    par.manager = data.manager;
                    par.pause = data.pause;
                }

                let axiosParams = api.axiosConfig(par);

                axiosParams.cancelToken = state.listAxiosCancelTokenSource.token;
                let params = new FormData();
                params.append('filterId', context.state.loadedItemsId.join(','));
                axios.post(process.env.VUE_APP_API_PATH + '/requests/',params, axiosParams).then(response => {
                    context.commit('setListAxiosCancelTokenSource', false);

                    if (typeof response.data === 'object' && +response.data.error > 0) {
                        api.errorHandler(response.data.error);
                    }
                    if(typeof(response.data.result.isLoadNextPage) !== 'undefined'){
                        context.commit('isLoadNextPage', response.data.result.isLoadNextPage);
                    } else {
                        if (typeof(response.data.result.items) !== 'undefined' && response.data.result.items.length) {
                            context.commit('isLoadNextPage', true);
                        } else {
                            context.commit('isLoadNextPage', false);
                        }
                    }

                    context.commit('setList', response.data.result);
                    context.commit('incrementPage');
                    context.commit('setLoad', false);
                    resolve(response.data.result);
                }).catch((error) => {
                    resolve([]);
                });
            } else {
                resolve([]);
            }


        }).catch(function (error) {
            context.commit('setListAxiosCancelTokenSource', false);
            resolve();
        });
    },
    getListSearch(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('clearListSearch');
            if (context.state.search.isLoadSearch) {
                if (context.state.listAxiosCancelTokenSource !== false) {
                    context.state.listAxiosCancelTokenSource.cancel();
                    context.commit('setLoadSearch', false);
                }
            }

            if(!context.state.search.isLoadSearch){
                context.commit('setLoadSearch', true);

                let request = axios.CancelToken.source();
                context.commit('setListAxiosCancelTokenSource', request);

                let axiosParams = api.axiosConfig({
                    query: state.search.query,
                });

                axiosParams.cancelToken = state.listAxiosCancelTokenSource.token;
                axios.get(process.env.VUE_APP_API_PATH + '/requests/', axiosParams).then(response => {

                    context.commit('setListAxiosCancelTokenSource', false);

                    if (typeof response.data === 'object' && +response.data.error > 0) {
                        api.errorHandler(response.data.error);
                    }
                    context.commit('setListSearch', response.data.result);
                    context.commit('setLoadSearch', false);
                });

            } else {
                resolve([]);
            }


        }).catch(function (error) {
            context.commit('setListAxiosCancelTokenSource', false);
            resolve();
        });
    },
    getListSearchNps(context, data) {
        context.commit('setIsSearchNpsPage', true);
        return new Promise((resolve, reject) => {
            context.commit('clearListSearch');
            if (context.state.search.isLoadSearch) {
                if (context.state.listAxiosCancelTokenSource !== false) {
                    context.state.listAxiosCancelTokenSource.cancel();
                    context.commit('setLoadSearch', false);
                }
            }

            if(!context.state.search.isLoadSearch){
                context.commit('setLoadNpsSearch', true);

                let request = axios.CancelToken.source();
                context.commit('setListAxiosCancelTokenSource', request);

                let axiosParams = api.axiosConfig({
                    query: state.searchNps.query,
                    isNps: data.isNps,
                    npsType: data.npsType
                });

                axiosParams.cancelToken = state.listAxiosCancelTokenSource.token;
                axios.get(process.env.VUE_APP_API_PATH + '/requests/', axiosParams).then(response => {

                    context.commit('setListAxiosCancelTokenSource', false);

                    if (typeof response.data === 'object' && +response.data.error > 0) {
                        api.errorHandler(response.data.error);
                    }
                    context.commit('setListSearchNps', response.data.result);
                    context.commit('setLoadNpsSearch', false);
                });

            } else {
                resolve([]);
            }


        }).catch(function (error) {
            context.commit('setListAxiosCancelTokenSource', false);
            resolve();
        });
    },
    getHintsSearch(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setHintsSearchLoad', true);
            axios.get(process.env.VUE_APP_API_PATH+'/search/', api.axiosConfig({query: state.search.query})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                } else {
                    context.commit('setHintsSearch', response.data.result.items);
                }
                context.commit('setHintsSearchLoad', false);
            }).catch(function (error) {
                context.commit('setHintsSearchLoad', false);
                resolve();
            });
        });
    },
    getHintsNpsSearch(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setHintsSearchLoad', true);
            axios.get(process.env.VUE_APP_API_PATH+'/search/', api.axiosConfig({query: state.searchNps.query, isNps: 'Y', npsType: data.npsType})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                } else {
                    context.commit('setHintsNpsSearch', response.data.result.items);
                }
                context.commit('setHintsSearchNpsLoad', false);
            }).catch(function (error) {
                context.commit('setHintsSearchNpsLoad', false);
                resolve();
            });
        });
    },
    actionRequest(context, data) {
        return new Promise((resolve, reject) => {
            let params = new FormData();
            for(let name in data.params){
                params.append(name, data.params[name]);
            }
            axios.post(process.env.VUE_APP_API_PATH + '/requests/'+data.id+'/', params, api.axiosConfig({
                action: data.action,
            })).then(response => {
                if (typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                    reject();
                } else {
                    if(!response.data.result.allowed) {
                        context.dispatch('popups/open', {name: 'errorModal'},{root:true});
                    } else {
                        if(state.search.isSearchPage){
                            context.dispatch('getListSearch');
                        }
                    }
                    resolve(response.data.result);
                }
            });
        }).catch(function (error) {
            resolve();
        });
    },
    getNpsRate(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_PATH + '/nps_rate/', api.axiosConfig({})).then(response => {
                if (typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                    reject();
                } else {
                    context.commit('setNpsRate', response.data.result.items);
                }
            });
        }).catch(function (error) {
            resolve();
        });
    },
    getItem(context, data) {
        return new Promise((resolve, reject) => {
            resolve();
        });
    },
}

const mutations = {
    setListAxiosCancelTokenSource(state, data) {
        state.listAxiosCancelTokenSource = data;
    },
    setLoad(state, data) {
        state.isLoad = data;
    },
    setLoadSearch(state, data) {
        state.search.isLoadSearch = data;
    },
    setIsSearchPage(state, data) {
        state.search.isSearchPage = data;
    },
    setSearchEmpty(state, data) {
        state.search.isEmpty = data;
    },
    setHintsSearchLoad(state, data) {
        state.search.isLoadHintsSearch = data;
    },
    clearList(state) {
        state.items = [];
        state.loadedItemsId = [];
    },
    clearListSearch(state) {
        state.search.sections = [];
        state.search.sectionsForbidden = [];
        state.search.isEmpty = false;
    },
    setList(state, data) {
        if (typeof data === 'object') {
            if (state.page > 1) {
                for (let i in data.items) {
                    state.items.push(data.items[i]);
                }
            } else {
                if (typeof data === 'object') {
                    state.items = data.items;
                } else {
                    state.items = [];
                }
            }
        } else {
            state.items = [];
        }

        state.loadedItemsId = [];
        for(let i in state.items) {
            state.loadedItemsId.push(state.items[i].id);
        }

        if (typeof state.items == "undefined") {
            state.items = [];
        }
    },
    setListSearch(state, data) {
        if (data.items.sections.length > 0 || data.items.sectionsForbidden.length > 0) {
            state.search.sections = data.items.sections ? data.items.sections : [];
            state.search.sectionsForbidden = data.items.sectionsForbidden ? data.items.sectionsForbidden : [];
        } else {
            state.search.sections = [];
            state.search.sectionsForbidden = [];
            state.search.isEmpty = true;
        }
    },
    isLoadNextPage(state, data) {
        state.isLoadNextPage = data;
    },
    setPage(state, data) {
        state.page = data;
    },
    setItem(state, data){
        let item = data.returnItem;
        for (let prop in state.items) {
            if(state.items[prop].id == item.id) {
                state.items[prop] = item;
            }
        }
    },
    setItemProps(state, data) {
        for (let prop in data.props) {
            data.item.props[prop] = data.props[prop]
        }
    },
    deleteRequest(state, data) {
        for (let prop in state.items) {
            if(state.items[prop].id == data.id) {
                state.items.splice(prop, 1);
            }
        }
    },
    changeRequest(state, data) {
        for (let prop in state.items) {

            if(state.items[prop].id == data.id) {
                state.items[prop].props[data.prop] = data.value;
                break;
            }
        }
    },
    incrementPage(state) {
        state.page++;
    },
    setWorkers(state, data) {
        state.workers = data;
    },
    setAllWorkers(state, data) {
        state.allWorkers = data;
    },
    setSearchQuery(state, data) {
        state.search.query = data;
    },
    setPreviousSections(state, data) {
        state.previousSections = data;
    },
    setHintsSearch(state, data) {
        state.search.hints = data;
    },
	setSearchIsOpen(state, data) {
        state.search.isOpen = data;
    },
    setDevPeriodPause(state, data) {
        state.devPeriodPause = data;
    },
    setNpsRate(state, data) {
        state.npsRate = data;
    },
    setSearchNpsQuery(state, data) {
        state.searchNps.query = data;
    },
    setHintsNpsSearch(state, data) {
        state.searchNps.hints = data;
    },
    setSearchNpsIsOpen(state, data) {
        state.searchNps.isOpen = data;
    },
    setLoadNpsSearch(state, data) {
        state.searchNps.isLoadSearch = data;
    },
    setIsSearchNpsPage(state, data) {
        state.searchNps.isSearchPage = data;
    },
    setSearchNpsEmpty(state, data) {
        state.searchNps.isEmpty = data;
    },
    setHintsSearchNpsLoad(state, data) {
        state.searchNps.isLoadHintsSearch = data;
    },
    clearListSearchNps(state) {
        state.searchNps.items = [];
        state.searchNps.isEmpty = false;
        state.searchNps.isSearchPage = false;
        state.searchNps.query = '';
    },
    setListSearchNps(state, data) {
        if (data.items.length > 0) {
            state.searchNps.items = data.items ? data.items : [];
            state.searchNps.isEmpty = false;
        } else {
            state.searchNps.items = [];
            state.searchNps.isEmpty = true;
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
