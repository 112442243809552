<template>
    <transition name="fade-popup">
        <div class="popup__full-size log" v-if="show">
			<div class="close-modal" @click="close">
				<svg>
					<use xlink:href="@/assets/img/big-close.svg#big-close"></use>
				</svg>
			</div>
            <div class="content" style="padding-top: 48px;">
                <div class="h1">Лог «{{ item.name }}»</div>
                <div class="log-list">
                    <div class="item" v-for="itemLog in item.props.log">
                        <span class="text-color-grey mb4">{{ itemLog.date }} <span v-if="itemLog.dateDiff">(через {{ itemLog.dateDiff }})</span></span>
                        <span v-for="row in itemLog.log" class="mb4"><strong class="mr8">{{ row.name }}:</strong>{{ row.value }}</span>
                        <span class="text-color-grey" v-if="itemLog.user.groupCode != 'null'">{{ itemLog.user.firstName }} {{ itemLog.user.lastName }} ({{ itemLog.user.groupCode+itemLog.user.number }})</span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    export default {
        name: "Log",
        components: {
            Tooltip,
            SubmitBtn,
            InputStyled,
        },
        methods: {
            close(){
                this.$store.commit('popups/close', {name: this.popups.log.name});
            }
        },
        props: {
            show: {
                default: function(){
                    return false;
                }
            }
        },
        data: function () {
            return {

            }
        },
        mounted() {
        },
        computed: {

            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>
    .fade-popup-enter-active, .fade-popup-leave-active {
        transition: opacity .15s;
    }
    .fade-popup-enter, .fade-popup-leave-to {
        opacity: 0;
    }
</style>
