import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import VCalendar from 'v-calendar';
import axios from 'axios';
import VueInputMask from './helpers/vue-inputmask.js';
import * as Sentry from '@sentry/browser';

if(process.env.NODE_ENV !== 'development'){
    Sentry.init({
        dsn: process.env.VUE_SENTRY_DSN
    });
}
//Vue.config.productionTip = false
window.app = createApp(App)
    .use(store)
    .use(router)
    .use(VCalendar)
    .use(VueInputMask)
    .mount('#app');

router.afterEach((to, from, next) => {
    store.commit('menus/setActivePath', {route: to});
})
axios.interceptors.request.use(function(config) {
    if(process.env.VUE_APP_AXIOS_DEBUG == "true"){ console.info("✉️ ", config); }
    if(process.env.VUE_APP_SHOW_PAGE_STAT == "true"){
        //console.log(config);
        if(config.params){
            config.params['show_page_stat'] = 'Y';
        }
        else{
            config['params'] = {'show_page_stat': 'Y'};
        }
    }
    return config;
}, function(error) {
    if (process.env.VUE_APP_AXIOS_DEBUG == "true") { console.error("✉️ ", error); }
    return Promise.reject(error);
});

axios.interceptors.response.use(function(response) {
    if(response.data.version){
        if (response.data.version != process.env.VUE_APP_API_VERSION && response.data.version != store.state.notifyPageVersion){
            store.dispatch('popups/open', {name: 'isNotifyReset'});
            store.commit('setNotifyPageVersion',response.data.version);
        }
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
