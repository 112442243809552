import axios from 'axios'
import api from "@/helpers/api";
import router from "@/router";
import store from "@/store";
import App from "@/App";
const state = {
    report: [],
    load: false,
    loadSpend: false,
    loadRemoveSpend: false,
    loadSendLoad: false,
    loadModal: false,
    loadModalBtnLoad: false,
    listAxiosCancelTokenSource: false,
    itemsPage: 1,
    isLoadNextPage: true
}

// getters
const getters = {
}

const actions = {
    getData(context, data) {
        return new Promise((resolve, reject) => {

                if (context.state.load) {
                    if (context.state.listAxiosCancelTokenSource !== false) {
                        context.state.listAxiosCancelTokenSource.cancel();
                        context.commit('setLoad', false);
                    }
                }

                if(data.type == 'current'){
                    context.commit('setPage', 1); 
                }

                if (state.itemsPage === 1) {
                    context.commit('setNewPage');
                    context.commit('isLoadNextPage', true);
                }


                let request = axios.CancelToken.source();
                context.commit('setListAxiosCancelTokenSource', request);

                let axiosParams = api.axiosConfig({
                    type: data.type,
                    page: state.itemsPage
                });

                axiosParams.cancelToken = state.listAxiosCancelTokenSource.token;

                context.commit('setLoad', true);
                axios.get(process.env.VUE_APP_API_PATH + '/fin/', axiosParams).then(response => {
                    if (typeof response.data === 'object' && +response.data.error > 0) {
                        api.errorHandler(response.data.error);
                    }

                    context.commit('setListAxiosCancelTokenSource', false);
                    context.commit('setReport', response.data.result);
                    context.commit('isLoadNextPage', response.data.result.report.length > 0);
                    context.commit('setLoad', false);
                    context.commit('incrementPage');
                    resolve(response.data.result);
                }).catch(function (error) {
                    context.commit('setListAxiosCancelTokenSource', false);
                    resolve(error);
                });
        });
    },
    setSpend(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setLoadSpend', true);
            axios.get(process.env.VUE_APP_API_PATH+'/fin/',api.axiosConfig({type: data.type, sum: data.sum, comment: data.comment})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                context.commit('setLoadSpend', false);
                resolve(response.data.result);
            }).catch(function (error) {
                resolve();
            });
        });
    },
    removeSpend(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setLoadRemoveSpend', true);
            axios.get(process.env.VUE_APP_API_PATH+'/fin/',api.axiosConfig({type: data.type, id: data.id})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                context.commit('setLoadRemoveSpend', false);
                resolve(response.data.result);
            }).catch(function (error) {
                resolve();
            });
        });
    },
    send(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setSendLoad', true);

            let formData = new FormData();
            formData.append('type', data.type);
            formData.append('text', data.text);

            for (let i in data.objects) {
                formData.append('objects[]', data.objects[i].id);
            }

            axios.post(process.env.VUE_APP_API_PATH+'/fin/',formData, api.axiosConfig())
                .then(response => {
                    context.commit('setSendLoad', false);
                    resolve(response);
                }).catch(function (error) {
                reject();
            });
        });
    },
}

const mutations = {
    setListAxiosCancelTokenSource(state, data) {
        state.listAxiosCancelTokenSource = data;
    },
    setReport(state, data) {
        if (state.itemsPage > 1) {
            if (data.report.length) {
                for(let i in data.report) {
                    state.report.push(data.report[i]);
                }
            }
        } else {
            state.report = data.report;
        }
    },
    clearReport(state, data) {
        state.report = [];
    },
    setLoad(state, data) {
        state.load = data;
    },
    setLoadSpend(state, data) {
        state.loadSpend = data;
    },
    setLoadRemoveSpend(state, data) {
        state.loadRemoveSpend = data;
    },
    setSendLoad(state, data) {
        state.loadSendLoad = data;
    },
    setNewPage(state) {
        state.report = [];
        state.itemsPage = 1;
    },
    incrementPage(state) {
        state.itemsPage++;
    },
    setPage(state, data) {
        state.itemsPage = data;
    },
    isLoadNextPage(state, data) {
        state.isLoadNextPage = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
