import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'requests_min',
        component: () => import(/* webpackChunkName: "Requests" */ '../views/Requests.vue')
    },
    {
        path: '/search/',
        name: 'search',
        component: () => import(/* webpackChunkName: "Requests" */ '../views/Search.vue')
    },
    {
        path: '/buh',
        name: 'buh',
        component: () => import(/* webpackChunkName: "Buh" */ '../views/Buh.vue')
    },
    {
        path: '/buh/:master',
        name: 'buhMaster',
        component: () => import(/* webpackChunkName: "Buh" */ '../views/Buh.vue')
    },
    {
        path: '/:parentStatus/',
        name: 'requests',
        // Заявки
        component: () => import(/* webpackChunkName: "Requests" */ '../views/Requests.vue')
    },
    {
        path: '/:parentStatus/:status/',
        name: 'requests_status',
        component: () => import(/* webpackChunkName: "Requests" */ '../views/Requests.vue')
    },
    {
        path: '/salaries/',
        name: 'salaries',
        component: () => import(/* webpackChunkName: "Salary" */ '../views/Salary.vue')
    },
    {
        path: '/salaries/payout',
        name: 'salaries-payout',
        component: () => import(/* webpackChunkName: "Salary" */ '../views/Salary.vue')
    },
    {
        path: '/salaries/archive',
        name: 'salaries-archive',
        component: () => import(/* webpackChunkName: "Salary" */ '../views/Salary.vue')
    },
    {
        path: '/salaries/current-month',
        name: 'salaries-current-month',
        component: () => import(/* webpackChunkName: "Salary" */ '../views/Salary.vue')
    },
    {
        path: '/fin-report/',
        name: 'fin-report',
        component: () => import(/* webpackChunkName: "FinReport" */ '../views/FinReport.vue')
    },
    {
        path: '/fin-report/current/',
        name: 'fin-report-current',
        component: () => import(/* webpackChunkName: "FinReportCurrent" */ '../views/FinReport.vue')
    },
    {
        path: '/fin-report/archive/',
        name: 'fin-report-archive',
        component: () => import(/* webpackChunkName: "FinReportArchive" */ '../views/FinReportArchive.vue')
    },
    {
        path: '/statistic',
        name: 'statistic',
        component: () => import(/* webpackChunkName: "Statistic" */ '../views/Statistic.vue')
    },
	{
        path: '/staff/',
        name: 'staff',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/Staff.vue')
        },
    },
	{
        path: '/staff/:status/',
        name: 'staffDetail',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/Staff.vue')
        },
    },
	{
        path: '/dev-period/',
        name: 'dev-period',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/DevPeriod.vue')
        },
    },
    {
        path: '/dev-period/:team/',
        name: 'dev-period-current',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/DevPeriod.vue')
        },
    },
    {
        path: '/workload/',
        name: 'workload',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/Workload.vue')
        },
    },
    {
        path: '/nps-collect/',
        name: 'nps-collect',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/NpsCollect.vue')
        },
    },
    {
        path: '/nps-collect/:status',
        name: 'nps-collect-current',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/NpsCollect.vue')
        },
    },
    {
        path: '/nps-measure/',
        name: 'nps-measure',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/NpsMeasure.vue')
        },
    },
    {
        path: '/nps-measure/:status',
        name: 'nps-measure-current',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/NpsMeasure.vue')
        },
    },
    {
        path: '/fill-specific/:id',
        name: 'fill-specific',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/FillSpecific.vue')
        },
    },
    {
        path: '/check-specific/:id',
        name: 'check-specific',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/CheckSpecific.vue')
        },
    },
    {
        path: '/fill-specific/:id/:back',
        name: 'fill-specific-other',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/FillSpecific.vue')
        },
    },
    {
        path: '/check-specific/:id/:back',
        name: 'check-specific-other',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/CheckSpecific.vue')
        },
    },
    {
        path: '/give-design/',
        name: 'give-design',
        component: function () {
            return import(/* webpackChunkName: "blank" */ '../views/GiveDesign.vue')
        },
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
