<template>
    <div class="h-nav">
        <div class="item" v-for="item in menuList" :key="item.name" :class="{'active': item.code == activeCode}">
            <router-link class="h-nav-item-link h-link" :to="'/dev-period/'+item.link"
                         :class="{'router-link-active': item.code == activeCode}">
                <span>{{ item.name }}<sup v-if="item.label > 0">{{ item.label }}</sup></span>
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: "SubMenuDevPeriod",
    props: {
        type: {
            default() {
                return 'public'
            },
        }
    },
    data: () => {
        return {
            pub_menu: {"error":"0","result":{"method":"GET","items":{"1270":{"name":"\u0417\u0430\u044f\u0432\u043a\u0438","code":"requests","link":"\/request\/requests","items":[{"name":"\u041d\u043e\u0432\u044b\u0435","code":"request_new","link":"\/request\/request_new"},{"name":"\u041e\u0434\u043e\u0431\u0440\u0435\u043d\u044b","code":"request_approved","link":"\/request\/request_approved"}]}}}}
        }
    },
    created() {
    },
    mounted() {
        this.$nextTick(()=>{
            this.fetchData();
            /*setInterval( () => {
                this.$store.dispatch('menus/getLabelsBuh',{isSub:true});
            }, 10000);*/
        })
    },
    computed: {
        ...mapState({
            menuList: state => state.menus.itemsSecondBuh,
        }),
        activeCode() {
            let code = '';

            if (this.$route.name == 'dev-period' && Object.values(this.menuList)[0]) {
                code = Object.values(this.menuList)[0].code;
            }
            if (this.$route.name == 'dev-period-current' && this.$route.params.team) {
                code = this.$route.params.team;
            }

            return code;
        }
    },
    methods: {
        fetchData() {
            setTimeout(()=>{
                this.$store.dispatch('menus/getMenu',{type: 'devPeriod', isSub: true});
            },200)
        },
    }
}
</script>
