import axios from 'axios'
import {jsonHeader} from '@/helpers/axiosHeaders'
import api from "@/helpers/api";

const state = {
    curItemData: {},
    items: {
        editable: {
            name: 'editable',
            value: false
        },
    },
}

// getters
const getters = {

}

const actions = {

}

const mutations = {
	toggleTumbler(state, data){
        state.items[data].value = !state.items[data].value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
