import axios from 'axios'
import api from "@/helpers/api";
import router from "@/router";
import store from "@/store";
import App from "@/App";
const state = {
    items: [],
    load: false,
    loadModal: false,
    loadModalBtnLoad: false,
}

// getters
const getters = {
}

const actions = {
    getList(context, data) {
        return new Promise((resolve, reject) => {

            context.commit('clearSalary');
            context.commit('setLoad', true);
            axios.get(process.env.VUE_APP_API_PATH+'/salary/',api.axiosConfig({type: data.type, fakeDate: window.fakeDate})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                context.commit('setSalary', response.data.result);
                context.commit('setLoad', false);
                resolve(response.data.result);
            }).catch(function (error) {
                resolve();
            });
        });
    },
    getMonth(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_PATH+'/salary/',api.axiosConfig({type: 'archive', month: data.month, fakeDate: window.fakeDate})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                resolve(response.data.result);
            }).catch(function (error) {
                resolve();
            });
        });
    },
    getModalData(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setModalLoad', true);
            axios.get(process.env.VUE_APP_API_PATH+'/salary/',api.axiosConfig({type: data.type, user: data.user, date: data.date, id: data.id})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                context.commit('setModalLoad', false);
                resolve(response.data);
            }).catch(function (error) {
                resolve();
            });
        });
    },
    applySalary(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setModalBtnLoad', true);
            let axiosParams = api.axiosConfig({});
            let params = new FormData();
            params.append('type', data.type);//@todo remove
            params.append('user', data.user);//@todo remove
            params.append('date', data.date);//@todo remove
            params.append('comment', data.comment);
            params.append('fakeDate', data.fakeDate);//@todo remove
            params.append('id', data.id);

            axios.post(process.env.VUE_APP_API_PATH + '/salary/',params, axiosParams).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                context.commit('setModalBtnLoad', false);
                resolve(response.data);
            }).catch(function (error) {
                resolve();
            });
        });
    },
}

const mutations = {
    setSalary(state, data) {
        state.items = data.items;
    },
    clearSalary(state, data) {
        state.items = [];
    },
    setLoad(state, data) {
        state.load = data;
    },
    setModalLoad(state, data) {
        state.loadModal = data;
    },
    setModalBtnLoad(state, data) {
        state.loadModalBtnLoad = data;
    },
    changeTableRow(state, data) {
        let userCode = data.salary.user.groupCode + data.salary.user.number,
            completedDate = data.completed;

        for(let month in state.items) {
            for(let row in state.items[month]) {
                if (typeof(state.items[month]['rows'][userCode]) !== 'undefined') {
                    if (data.salary.date == state.items[month]['rows'][userCode].date) {
                        state.items[month]['rows'][userCode].completed = completedDate;
                    }
                }
            }
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
