<template>
    <transition name="fade-popup">
        <div class="popup-wrapper" v-if="show">
            <div class="overlay" @mousedown="close"></div>

            <div class="popup-scroll-block">
              <div class="popup"
                   ref="overlayPopup"
                   :class="[addClass]"
                   :style="{top: (top - 0) +'px', width: width+'px'}">
                  <div class="popup__wrap" >
                      <div class="popup__close-wrap" @click="close" v-if="isBtnClose">
                          <span class="popup__close" ></span>
                      </div>
                      <slot></slot>
                  </div>
              </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Popup",
        props: {
            width: {
                default: function () {
                    return 720
                }
            },
            addClass: {default: ''},
            show: {
                default: function () {
                    return false
                }
            },
            isSetTop: {
                default: function () {
                    return true
                }
            },
            isBtnClose: {
                default: function () {
                    return true
                }
            },
            isSetStyled: {
                default: function () {
                    return false
                }
            },
            isSetBlank: {
                default: function () {
                    return false
                }
            },
            isResize: {
                default: function () {
                    return false
                }
            },
            isCloseOutSize: {
                default: function () {
                    return true
                }
            },
            name: ''
        },
        watch: {
            show: function () {
                if (this.show) {
                    if (this.isSetTop) {
                        this.updateViewportSize();
                    }
                    document.getElementsByTagName('body')[0].classList.add("noscroll");
                    if(document.querySelector('.header--black')){
                      document.querySelector('.header--black').classList.add("header-noscroll");
                    }
                    if(document.querySelector('.footer')){
                      document.querySelector('.footer').classList.add("footer-noscroll");
                    }
                }
            },
            isResize: function (newV, oldQV) {
                if (this.isSetTop && (newV !== oldQV)) {
                    this.updateViewportSize();
                }
            },
            top: function(newV, oldV){
                if(newV < 1){
                    this.$refs.overlayPopup.style.marginTop = '32px';
                }else{
                    this.$refs.overlayPopup.style.marginTop = '0px';
                }
            },
        },
        data: function () {
            return {
                top: 24,
            }
        },
        mounted: function () {
            if (this.isSetTop) {
                this.topListener();
            }
        },
        methods: {
            updateViewportSize: function () {
                this.$nextTick(function () {
                    if (typeof(this.$refs.overlayPopup) !== 'undefined' && this.$refs.overlayPopup !== null) {
                        let top = this.getViewportSize().h - this.$refs.overlayPopup.clientHeight;
                        if (top < 1) {
                            top = 1
                        }
                        if((top / 2) < 33){
                            this.top = 0;
                        }else{
                            this.top = (top / 2);
                        }
                    }
                });
            },
            updatePopupStyled: function () {
                this.$nextTick(function () {
                    this.$refs.overlayPopup.classList.add('popup--styled')
                })
            },
            topListener: function () {
                let self = this;
                this.$nextTick(function () {
                    self.updateViewportSize();
                    window.addEventListener('resize', self.updateViewportSize);
                });

            },

            getViewportSize: function () {
                let d = window.document.querySelector("html");
                return {w: d.clientWidth, h: d.clientHeight};
            },
            closeOutSize() {
                if(this.isCloseOutSize){
                    this.close();
                }
            },
            close: function () {
                this.$nextTick(()=>{
                    if(this.show && this.name){
                        this.$store.commit('popups/close', {name: this.name});
                        this.$emit('close');
                        document.getElementsByTagName('body')[0].classList.remove("noscroll");
                    } else {
                        this.$emit('close');
                        document.getElementsByTagName('body')[0].classList.remove("noscroll");
                    }
                })
            }
        },
        emits: ['close'],
        directives: {
            'clickoutside': {
                mounted: function(el, binding, vNode) {
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
                        //console.warn(warn);
                    }
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target) && (!e.target.classList.contains('click-outside-tooltip'))) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler;
                    document.addEventListener('mousedown', handler);
                },

                unmounted: function(el, binding) {
                    document.removeEventListener('mousedown', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null;
                }
            }
        }
    }
</script>

<style scoped>
    .fade-popup-enter-active, .fade-popup-leave-active {
        transition: opacity .15s;
    }
    .fade-popup-enter, .fade-popup-leave-to {
        opacity: 0;
    }
</style>
