<template>
    <div class="tumbler" ref="inpWrap" :class="{error: error, '--error': error,  'repeat-error': repeatError, 'width-auto': widthAuto}">
        <div class="option" v-for="(option, index) in options" 
            :class="{active: option.id == currentItem.id}" 
            @click="changeValue(option)" :key="option.id"
            :style="{padding: `0 ${padding}px`}">
            <div :style="{width: widthAuto ? 'auto' : elemWidth + 'px'}">{{option.name}}</div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "Tumbler",
        props: {
            modelValue: {default: ''},
            options: {},
            error: false,
            activeId: false,
            widthAuto: false,
            elemWidth: {
                default: function() {
                    return 100
                }
            },
            padding: {
                default: 8,
            },
        },
        data: function(){
            return {
              repeatError: false,
            }
        },
        watch: {
            error(val,oldVal){
                if(oldVal){
                    this.repeatError = true;
                }
				if(this.error){
					if(this.$el.closest('.popup-wrapper')){
                        let $top = 0;
                        let pr = this.$el.closest('.popup-wrapper');
                        $top += parseFloat(this.$refs.inpWrap.getBoundingClientRect().top + this.$el.closest('.popup-wrapper').scrollTop) - 10;
                        pr.scrollTo({top: $top, left: 0, behavior: 'smooth'});
                    }
                }
            },
        },
        emits: ['update:modelValue', 'change'],
        computed: {
            currentItem(){
                return this.modelValue ? this.modelValue : ''
            },
        },
        methods:{
            changeValue(option){
                if (option.id === this.modelValue.id) {
                    this.$emit('update:modelValue', { value: false, error: false });
                    this.$emit('change', { value: false, error: false});
                } else {
                    this.$emit('update:modelValue', option);
                    this.$emit('change', option);
                }
            }
        }
    }
</script>
