export let jsonHeader = {
    'Content-Type': 'application/json;charset=utf-8',
}

export let wwwFormHeader = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
}

export let fileHeader = {
    'Content-Type': 'multipart/form-data'
}
