<template>
    <div v-if="testData && (testData.requests.length > 0 || testData.countPayment) && isTestUser" class="test-notify">
	  	<img src="@/assets/img/notify.svg" alt="">
		<div class="text-bold mt16">
			Тестовые заявки:
		</div>
		<ul>
			<li v-for="request in testData.requests" :key="request.id">{{request.name}} <span @click="delDelRequest(request.id)" class="del">Х</span></li>
		</ul>
		<div v-if="testData.countPayment" class="text-bold mt24">
			Начислено зарплат по тестовым заявкам: {{testData.countPayment}}
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import { isMobile } from 'mobile-device-detect';
    export default {
        name: "TestNotify",
        computed: {
            ...mapState({
                testData: state => state.options.items.testData,
                user: state => state.auth.user,
            }),
            isTestUser: function () {
                return this.user.login.indexOf('900000000') !== -1;
            }
		},
        methods: {
            delDelRequest: function(id){
                console.log(id);   
                this.$store.dispatch('options/delTestRequest', id);
                this.$store.commit('options/delTestRequest', id); 
            }  
        },
    }
</script>
<style scoped>  
    .del{
        cursor: pointer;
        opacity: 0.5;
    }
    .del:hover{
        opacity: 1;
    }
</style>