import axios from 'axios'
import api from "@/helpers/api";
import router from "@/router";
import store from "@/store";
import App from "@/App";
const state = {
    sections: [],
    section: [],
    load: false,
    startUpdate: false,
    userEdit: {},
    listAxiosCancelTokenSource: false,
    workload: [],
}

// getters
const getters = {
}

const actions = {
    edit(context, data) {
        return new Promise((resolve, reject) => {
            context.commit('setEditUser', data.user);
            resolve();
        });
    },
    saveUser(context, data) {
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.append('firstName', data.firstName);
            params.append('secondName', data.secondName);
            params.append('lastName', data.lastName);
            params.append('num', data.num);
            params.append('phone', data.phone);
            params.append('email', data.email);
            params.append('group', data.group);
            params.append('team', data.team);

            params.append('action', data.is_create ? 'create' : 'change');
            params.append('id', data.id);

            axios.post(process.env.VUE_APP_API_PATH+'/users/',params, api.axiosConfig())
                .then(response => {
                    if(+response.data.error === 0) {
                        resolve(response.data);
                    } else if(response.data.error > 0) {
                        api.errorHandler(response.data.error).then(() => {reject()});
                        if(response.data.error == 410){
                            resolve(response.data);
                        }
                    }
                }).catch(function (error) {
                reject();
            });
        });
    },
    blockUser(context, data) {
        return new Promise((resolve, reject) => {
            let params = new FormData();

            params.append('action', 'block');
            params.append('id', data.id);

            axios.post(process.env.VUE_APP_API_PATH+'/users/',params, api.axiosConfig())
                .then(response => {
                    if(+response.data.error === 0) {
                        resolve();
                    } else if(response.data.error > 0) {
                        api.errorHandler(response.data.error).then(() => {reject()});
                    }
                }).catch(function (error) {
                reject();
            });
        });
    },
    unblockUser(context, data) {
        return new Promise((resolve, reject) => {
            let params = new FormData();

            params.append('action', 'unblock');
            params.append('id', data.id);

            axios.post(process.env.VUE_APP_API_PATH+'/users/',params, api.axiosConfig())
                .then(response => {
                    if(+response.data.error === 0) {
                        resolve();
                    } else if(response.data.error > 0) {
                        api.errorHandler(response.data.error).then(() => {reject()});
                    }
                }).catch(function (error) {
                reject();
            });
        });
    },
    getSections(context, data) {
        return new Promise((resolve, reject) => {

            context.commit('setLoad', true);

            let axiosParams = api.axiosConfig();

            axios.get(process.env.VUE_APP_API_PATH+'/staff/',axiosParams).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                } else {
                    context.commit('setSections', response.data.result);
                }
                context.commit('setLoad', false);
                resolve(response.data.result);
            }).catch(function (error) {
                resolve(error);
            });
        });
    },

    getSection(context, data) {
        return new Promise((resolve, reject) => {

            context.commit('setLoad', true);

            if (context.state.load) {
                if (context.state.listAxiosCancelTokenSource !== false) {
                    context.state.listAxiosCancelTokenSource.cancel();
                    context.commit('setLoad', false);
                }
            }


            let request = axios.CancelToken.source();
            context.commit('setListAxiosCancelTokenSource', request);

            let axiosParams = api.axiosConfig({sect: data.sect});
            axiosParams.cancelToken = state.listAxiosCancelTokenSource.token;

            axios.get(process.env.VUE_APP_API_PATH+'/staff/',axiosParams).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                } else {
                    context.commit('setSection', response.data.result);
                }
                context.commit('setListAxiosCancelTokenSource', false);
                context.commit('setLoad', false);
                resolve(response.data.result);
            }).catch(function (error) {
                context.commit('setListAxiosCancelTokenSource', false);
                resolve(error);
            });
        });
    },
    getWorkload(context, data) {
        return new Promise((resolve, reject) => {
            let axiosParams = api.axiosConfig({ action: 'workload' });
            axios.get(process.env.VUE_APP_API_PATH+'/staff/',axiosParams).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                } else {
                    context.commit('setWorkload', response.data.result);
                }
            }).catch(function (error) {
                resolve(error);
            });
        });
    },
}

const mutations = {
    setListAxiosCancelTokenSource(state, data) {
        state.listAxiosCancelTokenSource = data;
    },
    setLoad(state, data) {
        state.load = data;
    },
    setEditUser(state, data) {
        state.editUser = data;
    },
    setSections(state, data) {
        state.sections = data.items;
    },
    setSection(state, data) {
        state.section = data.sect;
    },
    setWorkload(state, data) {
        state.workload = data.items;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
